.reveal
  opacity: 0
  transition: all 0.5s ease-in-out
  transform: translate(0px, 30px)

  +mq($until: phablet)
    opacity: 1 !important
    transform: translate(0px, 0px) !important


  &.is-active
    opacity: 1
    transform: translate(0px)

  &--image
    overflow: hidden
    position: relative
    height: auto

    img
      position: relative
      right: -800px
      height: auto
      transition: all 0.5s ease-in-out

    &.is-active
      img
        right: 0px

  &--left
    opacity: 0
    left: -1500px
    transition: left 0.8s ease-in, opacity 1s ease-in-out
    position: relative

    +mq($until: phablet)
      opacity: 1 !important
      left: 0px !important

    &.is-active
      opacity: 1
      left: 0

  &--right
    opacity: 0
    right: -1500px
    transition: right 0.8s ease-in, opacity 1s ease-in-out
    position: relative

    +mq($until: phablet)
      opacity: 1 !important
      left: 0px !important


    &.is-active
      opacity: 1
      right: 0

.scroll--image
  padding-left: 40px !important

#scroll--image
  overflow: hidden
  background: black
  padding-left: 0px
  padding-right: 0px
  max-width: 1080px

// disable all reveal animations after orientation change
.is-revealed
  .reveal, .reveal--left, .reveal--right
    opacity: 1!important
