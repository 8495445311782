.download
  padding: 60px 0 45px
  text-align: center

  &__heading
    +u-font(30, 38)
    text-transform: none
    margin-bottom: 20px

  &__item
    width: 174px
    display: inline-block
    text-align: left
    margin: 15px $gutter-small/2
    +u-font(16, 24)
    +f_bold

    +mq($from: tablet)
      margin: 15px $gutter-medium/2

    +mq($from: desktop)
      margin: 15px $gutter-large/2

  &__cover
    box-sizing: border-box
    width: 100%
    border: 1px solid c(grey, d)
    transition: box-shadow .3s ease
    margin-bottom: 25px

    .download__item:hover &, .download__item:active &, .download__item:focus &
      box-shadow: 0px 0px 8px #00000029

    > img
      display: block
