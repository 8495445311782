.timeline
  $tlBorderWidth: 4px
  $tlBorder: $tlBorderWidth solid c(black)
  $tlBoxMargin: 10rem
  $tlBoxMargin-mobile: 5rem

  display: flex

  +mq($until: tablet)
    flex-direction: column

  &__box
    display: flex
    flex-direction: column
    position: relative
    width: 100%

    +mq($from: tablet)
      max-width: 50%

    &--center
      justify-content: space-between
      margin-left: auto
      margin-right: auto
      max-width: inherit

      +mq($until: tablet)
        display: inline-flex
        width: auto

    //last box with circle
    &--end
      transform: translateY(-50%)
      &:before
        background-color: c(white, 500)
        border: $tlBorder
        border-radius: 50%
        content: ''
        height: 1rem
        position: absolute
        top: 50%
        left: $tlBorderWidth/2
        width: 1rem
        transform: translateX(-50%) translateY(-50%)

        +mq($from: tablet)
          transform: translateX(50%) translateY(-50%)
          right: 0
          left: auto

    &--left, &--right
      +mq($until: tablet)
        border-left: $tlBorderWidth solid c(black)

    &--left
      justify-content: flex-start
      padding-top: $tlBoxMargin-mobile

      +mq($from: tablet)
        padding-top: $tlBoxMargin
        border-right: $tlBorderWidth/2 solid c(black)

    &--right
      margin-left: auto

      +mq($until: tablet)
        padding-top: $tlBoxMargin-mobile
      +mq($from: tablet)
        border-left: $tlBorderWidth/2 solid c(black)





  &__content
    position: relative

    //content color variants
    &--purple-base
      .timeline__prefix, figcaption, blockquote
        color: c(purple, base)
      .timeline__indent
        background-color: c(purple, base)
    &--cyan-base
      .timeline__prefix, figcaption, blockquote
        color: c(cyan, base)
      .timeline__indent
        background-color: c(cyan, base)
    &--red-base
      .timeline__prefix, figcaption, blockquote
        color: c(red, base)
      .timeline__indent
        background-color: c(red, base)


    @at-root .timeline__box--end &
      font-size: .9rem
      margin-right: 4.5rem
      padding-left: 2rem
      +f_bold

      .who
        +text-caps

      +mq($from: tablet)
        text-align: right


    @at-root .timeline__box--left &
      +mq($from: tablet)
        padding-right: 4.5rem
        text-align: right
      +mq($until: tablet)
        padding-left: 4.5rem
    @at-root .timeline__box--right &
      padding-left: 4.5rem


    .timeline__prefix
      margin-right: .5rem
      +f_bold
      text-transform: uppercase

  // multiple content inside one timeline__box
  &__content + &__content
    +mq($until: tablet)
      padding-bottom: $tlBoxMargin-mobile
  &__content + &__content:last-child
    +mq($until: tablet)
      padding-bottom: 0

  &__figure
    margin-bottom: $tlBoxMargin-mobile
    position: relative

    +mq($from: tablet)
      margin-bottom: 0

    img
      +mq($from: phablet)
        max-width: 70%
      //+mq($from: tablet)
        max-width: 70%

    &--w-100
      img
        max-width: 100%

    figcaption
      +mq($from: tablet)
        position: absolute
        bottom: 2rem
        right: 0

      @at-root .timeline__box--left .timeline__figure figcaption
        left: 0
        right: auto
        text-align: right

      .figure__title
        letter-spacing: 1px
        text-transform: uppercase
        margin-bottom: .25rem

      .figure__title, .figure__subtitle
        background-color: c(white)
        +f_medium
        padding: .25rem


  // colored indentation on timeline__box
  &__indent
    position: absolute
    border: $tlBorderWidth solid c(black)
    //width: 3rem
    height: 1.25rem
    +u-trans()
    top: 0
    z-index: 1

    +mq($until: tablet)
      top: -1.5rem

    //indent left
    @at-root .timeline__box--left .timeline__indent
      +mq($until: tablet)
        border-left-color: c(white)
        border-top-right-radius: $tlBorderWidth
        border-bottom-right-radius: $tlBorderWidth
        left: -$tlBorderWidth

      +mq($from: tablet)
        border-right-color: c(white)
        border-top-left-radius: $tlBorderWidth
        border-bottom-left-radius: $tlBorderWidth
        right: -$tlBorderWidth

    //indent right
    @at-root .timeline__box--right .timeline__indent
      border-left-color: c(white)
      border-top-right-radius: $tlBorderWidth
      border-bottom-right-radius: $tlBorderWidth
      left: -$tlBorderWidth

  &__question
    +f_bold
    +u-font(20,28)
    border: $tlBorderWidth solid c(black)
    padding: .5rem
    border-bottom-right-radius: 15px

    +mq($from: tablet)
      margin-left: auto
      margin-right: auto

  &__text
    margin-bottom: 2rem
    +mq($from: tablet)
      margin-bottom: 6rem
    blockquote
      +u-font(24, 30)

      +mq($from: tablet)
        +u-font(30, 40)

      cite
        display: block
        margin-top: .5rem
        +u-font(13,18)
        text-transform: uppercase
        font-style: normal

  &__spacer
    position: relative

    +mq($until: tablet)
      padding-bottom: 1rem

    +mq($from: tablet)
      padding-bottom: $tlBoxMargin*.6

    &:before
      background-color: c(black)
      content: ''
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: $tlBorderWidth

      +mq($from: tablet)
        left: calc(50% - #{$tlBorderWidth}/2)

// hide first spacer
//.timeline__spacer.desktop--hidden:first-of-type
  display: none
