.video
  position: relative
  padding-bottom: 56.25%
  height: 0
  overflow: hidden
  max-width: 100%
  height: auto

  iframe,
  object,
  embed
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  &__fallback
    position: absolute
    left: 0
    top: 0
    z-index: 2

  &__wrapper
    z-index: 3
    iframe
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%

.background-video
  iframe
    position: absolute

    +mq($until: tablet_ls)
      position: relative
      max-width: 100%

  &--corner
    //padding: 40.3% 0 0 0
    iframe
      position: absolute
      border-radius: 0px 0px 65px 0px
      //margin-top: 80px
