.button
  background: c(grey, dd)
  padding-bottom: 15px
  padding-top: 15px
  padding-left: 20px
  min-width: 200px
  display: inline-block
  position: relative
  height: 60px
  overflow: hidden
  +mq($until: tablet)
    margin-top: 20px

  &.download
    svg
      max-width: 15px
      position: absolute
      right: 20px
      height: 22px
      z-index: 999
      path
        fill: c(grey,m)

    &:before
      display: none


  &:before
    content: ''
    display: block
    width: 9px
    height: 9px
    border-top: 2px solid c(white)
    border-right: 2px solid c(white)
    position: absolute
    right: 15px
    transform: rotate(45deg)
    top: 25px
    z-index: 99999

  span
    +f_bold
    color: c(white)
    text-transform: uppercase
    font-size: 14px
    display: block
    z-index: 9999
    position: absolute
    letter-spacing: 1px
    top: 22px
    height: 55px

  &:after
    content: ''
    height: 0px
    left: 0px
    bottom: 0px
    position: absolute
    background: c(primary, base)
    width: 100%
    display: block
    transition: all 0.4s ease-in-out
    transform: translateY(-100px)

  &:hover
    &:after
      transition: height 0.4s ease-in-out
      height: 100%
      transform: translateX(0px)

  &--down
    min-width: 330px
    &:before
      transform: rotate(135deg)