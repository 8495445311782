.outro
  &--white
    background: c(white, 500)
  &--grey
    background: c(grey, l)
  &--green
    background: c(green, l)

  h2.title
    font-size: 33px
    margin-bottom: 16px

  hr.divider
    padding-top: 0
    margin-top: 16px
    margin-bottom: 24px

  p.sub
    +f_superbold
    text-transform: uppercase

  p.answer
    margin-bottom: 32px

  .bold
    +f_bold

  &__portrait
    img
      box-shadow: 4px 4px 20px 0px rgba(33,37,41, .25)
      margin: 0


  .orange
    color: c(primary, base)
    text-transform: uppercase
  .green
    color: c(green, base)
    text-transform: uppercase

  h2.outrotitle
    margin-bottom: 12px
  h3.subtitle
    +f_regular
    font-weight: normal
    font-size: 2rem
    line-height: 2rem