.further
  margin-bottom: 80px

  &__title
    h2
      display: block
      position: relative
      &:after
        content: ''
        position: absolute
        width: 38px
        height: 7px
        display: block
        background: c(black)
        bottom: -15px
        left: 2px

  &__button
    text-align: center
    margin-bottom: 100px

    +mq($until: phablet)
      text-align: left

    a
      display: inline-block
      width: 370px

      +mq($until: phablet)
        max-width: 300px

  &__downloads
    a
      padding: 20px
      +mq($until: phablet)
        height: 80px


  &__img
    margin-bottom: 45px
    img
      transition: all 0.3s ease-in-out
      margin-bottom: 0px
  p
    display: block
    min-height: 110px
    margin-bottom: 0px

  &:hover
    img
      display: block
      box-shadow: 7px 9px 22px 1px rgba(0, 0, 0, .23)

h3.further-headline
  +u-font(30, 36)
  +f_bold
  text-transform: none

.slider-further--item
  margin: 0px 20px 0px 20px
  +mq($until: phablet)

  .slider-further-wrapper
    width: 90%

    .slider-further__title
      h2
        +u-font(20, 26)
        +f_bold
        margin-bottom: 10px
        margin-top: 20px
      p
        +u-font(20, 26)
        +f_regular

.slick-arrow
  position: absolute
  font-size: 0
  &:focus
    outline: none

.slick-prev, .slick-next
  background: transparent
  width: 60px
  height: 60px
  z-index: 9
  border: none
  top: 32%

.slick-prev
  left: 0
  margin-left: -40px
  +mq($until: phablet)
    margin-left: 0px

.slick-next
  right: -50px
  +mq($until: phablet)
    right: 0px

.slick-prev:before
  content: ' '
  border: solid c(primary, base)
  border-width: 0 5px 5px 0
  display: inline-block
  line-height: 1
  height: 25px
  width: 25px
  opacity: 1
  transform: rotate(135deg)
  -webkit-transform: rotate(135deg)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.slick-next:before
  content: ' '
  border: solid c(primary, base)
  border-width: 0 5px 5px 0
  display: inline-block
  line-height: 1
  height: 25px
  width: 25px
  opacity: 1
  transform: rotate(-45deg)
  -webkit-transform: rotate(-45deg)
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.slider-further__corporate
  +mq($until: phablet)
    margin: 0px !important

  .slider-further__corporate-item
    .slider-further__corporate-item--wrapper
      width: 94%
      max-width: 340px
      height: 320px
      background: #9e007e
      -webkit-border-radius: 0px 0px 50px 0px
      border-radius: 0px 0px 50px 0px
      +mq($until: laptop)
          height: 320px
      +mq($until: desktop)
        height: 300px
        margin-left: 20px
      +mq($until: tablet)
        width: 91% !important
      +mq($until: phablet)
        height: 250px
        width: 100%
        margin: auto
      &:hover
        box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
        -webkit-box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
        -moz-box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
        .financial--text
          color: #FFF
          opacity: 0.85
      h2
        color: white
        +f_bold
        font-weight: 300
        +u-font(23, 30)
        text-align: left
        padding-left: 30px
        +mq($until: desktop)
          padding-left: 30px
      &:hover
        h2
          font-weight: 300
          +u-font(23, 30)
          color: #fff
          opacity: 0.85
      &--violett
        background: #9e007e !important
      &--cyan-base
        background: c(cyan, base) !important
  .slick-prev, .slick-next
    top: 42%
  .slick-prev
    left: 0
    margin-left: 0px
    +mq($until: phablet)
      margin-left: 0px
      left: -30px

  .slick-next
    right: -25px
    +mq($until: super_wide)
      right: 25px
    +mq($until: laptop)
      right: 0px
    +mq($until: phablet)
      right: -30px

.slider--narrow
  +mq($until: phablet)
    padding: 0px 40px !important
    .slick-prev
      left: 0px
    .slick-next
      right: 0px
