.intro

  &__content
    +f_bold
    &--grey

      p
        color: c(grey,d)
        +f_bold

    &--yellow
      p
        color: c(primary)
    &--purple
      p
        color: c(purple, base)

    p
      +u-font(22, 28)

      +mq($until: wide)
        +u-font(26, 36)

      +mq($until: desktop)
        +u-font(22, 28)

      +mq($until: tablet)
        +u-font(20, 28)

      +mq($until: phablet)
        +u-font(18, 28)

    p.primary
      color: c(primary, base)