@import grid-basic
@import grid-mq

$columns: 12

// basic settings
$spacing-small: 1rem
$spacing-medium: 1.5rem
$spacing-large: 0rem


.section
  +section-mq()

  +mq($until: desktop)
    margin-bottom: 0px !important

  &.section--no-spacing
    margin-bottom: 0 !important

  &.section--primary
    +c(white)
    +bg(primary)

  .content
    +content-mq()
    width: 100%

    +mq($until: tablet)
      padding: 0px 100px !important

    +mq($until: phablet)
      padding: 0px 10px !important

    &.content--no-padding
      padding: 0px 12px !important
    &.content--full
      max-width: 100%
    &.content--no-gutter
      padding-left: 0
      padding-right: 0
    &--center
      text-align: center
    &--right
      text-align: right

    .col
      +col-mq()

      &.col--no-gutter
        padding-left: 0
        padding-right: 0

      // If you use nested structures use:
      // .section > .content > .col.col--nested.content > .content > .col.col--nested-content > content > …
      &.col--nested-content
        padding-left: 0
        padding-right: 0
        & > .content
          padding-left: 0
          padding-right: 0

      &.col--shift-25
        margin-top: -25%

      &.col--shift-35
        margin-top: -35%

      @for $i from 1 through $columns
        &--#{$i}
          +span(12)
          +mq($from: tablet)
            +span($i)

        &--#{$i}--laptop
          +mq($from: laptop)
            +span($i)

        &--lead--#{$i}
          +lead(0)
          +mq($from: tablet)
            +lead($i)

        &--trail--#{$i}
          +trail(0)
          +mq($from: tablet)
            +trail($i)

        &--pull--#{$i}
          +mq($from: laptop)
            +pull($i)

        &--push--#{$i}
          +mq($from: laptop)
            +push($i)
