.share
  max-width: 1440px
  margin: auto
  text-align: center
  padding: 80px 0px

  &__button
    display: inline-block
    background: c(primary)
    padding: 10px 15px
    position: relative
    border-radius: 10px
    transition: all 0.3s ease-in-out

    &:hover
      opacity: 0.7

    span
      color: c(white)
      +f-bold
      svg
        position: relative
        margin-right: 10px
        height: 22px
        width: 22px
        top: 5px
        path
          fill: c(white)