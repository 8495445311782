.teaser
  display: block

  &__image-frame
    box-sizing: border-box
    overflow: hidden
    margin-bottom: 20px

  &__image
    display: block
    transition: all 0.8s ease-in-out

    .teaser:hover &, .teaser:active &, .teaser:focus &
      transform: scale(1.2, 1.2)

  &__title
    color: c(cyan, base)
    font-size: 30px
    line-height: 30px
    text-transform: none

    &::before
      background: no-repeat url(~/assets/images/01_Index/amprion-00-pfeil-teasertext.svg) center / 10.91px 13.89px
      display: inline-block
      width: 15px
      height: 20px
      content: ""

  &__content
    p
      +u-font(20, 27)


  &--flipped
    text-align: right
