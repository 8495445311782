.background
  &--wrapper
    &__yellow
      background: c(yellow, base)
      +mq($until: tablet)
        > .col
          padding-top: 40px !important
    &__white
      background: #fff
    &__orange
      background: c(orange, base)
    &__grey-light
      background: c(grey, l)
    &__grey-light--special
      background: c(grey, l)
      max-width: 1440px
      height: 580px
      position: absolute
      +mq($until: phablet)
        height: 160px
    &__purple
      background: c(purple, berry)
      max-width: 1440px
      height: 580px
  &-gradient
    &--yellow

      &--50-50
        background-image: linear-gradient(to right, c(yellow, base), c(yellow, base), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1))
      &--1-5
        max-width: 1440px
        margin: auto
        background-image: linear-gradient(to right, c(yellow, base), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1), rgba(255,255,255,1))

.section--zahlen
  +mq($until: phablet)
    border: 30px solid #00A9E0
