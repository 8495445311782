.story
  &--header
    max-width: 1440px
    margin: auto
    margin-top: 80px
    z-index: 3
    border-radius: 0px 0px 65px 0px
    overflow: hidden
    position: relative
    transition: all 0.2s ease-in-out

    &.is-hidden
      opacity: 0

    +mq($until: phablet)
      margin-top: 0px
      width: 100%

    &__overflowed
      overflow: visible

    &__animated
      & > img,
      & > div
        position: absolute
        left: 0
        top: 0
        z-index: 3

      & > div
        z-index: 5

  &--automatisierer
    +mq($until: desktop)
      margin-top: 100px
    +mq($until: tablet)
      margin-bottom: 100px
    width: 100%
    background: c(primary)
    background-image: repeating-linear-gradient(135deg, transparent, transparent 15px, rgb(255, 255, 255) 15px, rgb(255, 255, 255) 25px)

    &__content
      background: c(white)
      padding: 50px

      span
        font-size: 48px
        line-height: 55px
        color: c(primary)
        display: block
        padding-bottom: 80px
        +f-bold

      img
        margin-bottom: 50px

      p
        +f_bold
        margin-bottom: 0px

    &__wrapper
      padding: 20px

  &-link
    color: c(primary, base)
    font-size: 12pt
    text-transform: uppercase

    strong
      +f_superbold

  &--meile
    height: 600px
    position: relative
    margin-bottom: 100px
    +mq($until: desktop)
      height: auto

    &__left
      height: 600px
      max-width: 60%
      position: relative
      +mq($until: desktop)
        max-width: 100%

    &__bg
      width: 110%
      height: 390px
      position: absolute
      bottom: 0px
      background: c(primary)
      opacity: 0.5
      z-index: -1
      left: -64px
      +mq($until: tablet)
        left: -37px

    &__img
      z-index: 9999
      max-width: 708px
      position: absolute
      top: -20px
      left: 0
      +mq($until: tablet)
        top: 0px

    &__img2
      z-index: 9999
      max-width: 300px
      position: absolute
      top: 0px
      right: 100px

      +mq($until: desktop)
        max-width: 200px
        right: 30px
        top: 240px
      +mq($until: tablet)
        position: relative
        top: -70px
        left: 10px
      +mq($until: phablet)
        top: -20px

      span
        padding-top: 35px
        font-size: 18px
        line-height: 25px
        position: absolute
        bottom: -65px
        left: 0px

    &__dot
      position: absolute
      font-size: 20px
      line-height: 26px
      width: 25px
      height: 25px
      background: c(primary)
      text-align: center
      border-radius: 25px
      +f-bold
      color: c(white)

      &--1
        left: 10px
        bottom: 180px
        +mq($until: phablet)
          bottom: 350px

      &--2
        left: 200px
        bottom: 450px
        +mq($until: tablet)
          left: 150px
        +mq($until: phablet)
          bottom: 530px
          left: 70px

      &--3
        left: 429px
        top: 10px
        +mq($until: phablet)
          left: 179px
          top: -14px

    // &--4
    //   right: 79px
    //   bottom: 260px

    &__right
      position: absolute
      width: 40%
      bottom: 20px
      left: 260px
      padding-left: 60px

      +mq($until: tablet)
        bottom: 300px
        left: 200px
        width: 60%
      +mq($until: desktop)
        padding-top: 15px
      +mq($until: phablet)
        left: 0px
        padding-left: 0px
        width: 90%
        bottom: 380px

      p
        &.imagetitle
          position: absolute
          bottom: -105px
          font-size: 18px
          line-height: 25px

          +mq($until: tablet)
            bottom: -142px

      ul
        list-style: none

      li
        padding-bottom: 10px
        font-size: 18px
        line-height: 25px
        position: relative
        padding-left: 35px

        span
          display: inline-block
          width: 25px
          height: 25px
          background: c(primary)
          opacity: 0.5
          text-align: center
          border-radius: 25px
          margin-right: 10px
          margin-top: 5px
          +f-bold
          position: absolute
          left: -0px
          top: -7px

        p
          margin-bottom: 0px
          font-size: 18px
          line-height: 25px