$lineCounterSize: 80px

.line

  &__indicator
    position: relative
    margin-top: 3rem
    margin-bottom: 2rem

    +mq(tablet)
      margin-left: 15%

  &__container
    position: relative

  &__counter
    color: c(white)
    +f_bold
    font-size: 3rem
    margin-top: .25rem
    background-color: c(purple, berry)
    padding: 1rem
    border-radius: 50%
    display: inline-flex
    width: $lineCounterSize
    height: $lineCounterSize
    line-height: $lineCounterSize
    justify-content: center
    align-items: center
