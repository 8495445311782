.quote
  padding: 110px !important
  border-radius: 0px 0px 65px 0px
  background: c(primary, base)
  +mq($until: phablet)
    padding: 25px !important

  &--text
    p
      +u-font(30, 40)
      +f_bold
      color: #FFF
      // margin-bottom: 40px
      max-width: 640px
      width: stretch

  &--sub
    p
      +f_medium
      color: #FFF
      +u-font(13, 16)
      letter-spacing: 1px
      text-transform: uppercase

    span
      color: #FFF
      display: block
      +u-font(13, 16)
      text-align: right

  &--black
    p,
    span
      color: black

  &--darkblue
    p,
    span
      color: c(darkblue, base)

  &--red
    p,
    span
      color: c(red, base)

  &--right
    p,
    span
      text-align: right
