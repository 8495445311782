
// basic settings
$spacing-small: 1rem !default
$spacing-medium: 3rem !default
$spacing-large: 4rem !default

$gutter-small: 1rem !default
$gutter-medium: 3rem !default
$gutter-large: 2.5rem !default

$debug: false !default

// section-mq mixin
=section-mq()
  +section($spacing-small)
  // media query for section-spacing
  +mq($from: tablet)
    +section($spacing-medium)
  +mq($from: desktop)
    +section($spacing-large)

// content-mq mixin
=content-mq()
  +content($gutter-small)
  // media query for gutter and content max-width
  +mq($from: tablet)
    +content($gutter-medium)
    //+content($gutter-medium, mq-get(tablet))
  +mq($from: wide)
    +content($gutter-large, mq-get(desktop))
  +mq($from: wide)
    +content($gutter-large, mq-get(wide))

// col-mq mixin
=col-mq()
  +col($gutter-small)
  // media query for gutter
  +mq($from: tablet)
    +col($gutter-medium)
  +mq($from: desktop)
    +col($gutter-large)
