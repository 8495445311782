.menu
  background: c(white)
  border-bottom: 1px solid c(grey, d)
  padding-top: 50px
  padding-bottom: 30px
  height: auto
  top: -350px
  position: absolute
  width: 100%
  z-index: 0
  transition: all 0.3s ease-in-out
  padding-left: 10px

  @media print
    display: none


  +mq($until: tablet_ls)

    &.mobile--open
      height: auto

    position: relative
    top: -840px
    padding-top: 20px
    padding-bottom: 20px
    height: 65px
    overflow: hidden

  &__right
    opacity: 0
    height: 0px
    position: absolute
    width: calc(100% - 315px)
    right: 0px
    top: -5px
    height: 200px
    vertical-align: top
    pointer-events: none
    transition: all 0.1s ease-in-out
    +mq($until: tablet_ls)
      display: none

    h2, p
      display: block
      width: 400px

    h2
      font-size: 26px
      +f_superbold

      .line
        width: 0px
        height: 4px
        font-size: 0px
        margin-top: 3px
        padding: 0
        background: c(black)
        transition: width 0.3s ease-in-out

    p
      font-size: 18px

    img
      width: 290px
      position: absolute
      right: 15px
      top: 0px
      transition: all 0.3s ease-in-out
      opacity: 0

    &:before
      content: ''
      display: block
      width: 1px
      height: 200px
      position: absolute
      left: -20px
      background: c(grey, m)


  &__wrapper
    max-width: 1060px
    margin: auto
    position: relative

    .mobile__trigger
      display: none
      +mq($until: tablet_ls)
        display: block
        padding-left: 20px


    ul
      list-style: none
      +mq($until: tablet_ls)
        padding-left: 20px

      li
        width: 300px
        cursor: pointer

        span
          font-size: 17px
          text-transform: uppercase
          +f_bold
          display: block
          padding-bottom: 17px
          transition: all 0.3s ease-in-out

        a
          color: c(black)

          svg
            height: 22px

        &:hover
          span
            padding-left: 10px

          .menu__right
            opacity: 1
            height: auto

            img
              right: 0px
              opacity: 1

            h2
              .line
                width: 18px
                height: 4px


  &--1,
  &--2,
  &--3,
  &--4,
  &--0
    &.active
      top: 65px
      +mq($until: tablet_ls)
        top: 1px

  &--3
    li
      padding-left: 25px

    svg
      max-width: 15px
      position: absolute
      left: 0px
      +mq($until: tablet_ls)
        left: 20px

  &--4
    span
      +f_superbold
      padding-left: 20px
      font-size: 20px

.mobile__trigger
  font-size: 20px !important

  &:after
    content: ''
    width: 10px
    height: 10px
    display: block
    border-top: 3px solid c(black)
    border-right: 3px solid c(black)
    transform: rotate(135deg)
    position: absolute
    right: 20px
    top: 7px
    transition: all 0.3s ease-in-out

.mobile--open
  .mobile__trigger
    &:after
      transform: rotate(-45deg)

.arrow
  border: solid c(primary, base)
  border-width: 0 3px 3px 0
  display: inline-block
  padding: 6px
  margin-bottom: 2px

  &__down
    transform: rotate(45deg)
    -webkit-transform: rotate(45deg)

  &__right
    transform: rotate(-45deg)
    -webkit-transform: rotate(-45deg)

  &__left
    transform: rotate(135deg)
    -webkit-transform: rotate(135deg)