.tour
  padding-bottom: 100px

  +mq(tablet_xl)
    max-width: 1440px
    margin: 80px auto
    position: relative
    overflow: hidden
    padding: 0
    background-color: #000

  &__focus-content
    display: none

    +mq(tablet_xl)
      display: block
      position: absolute
      opacity: 0
      top: 0
      left: 100%
      right: 0
      bottom: 0
      z-index: 105
      transition: opacity .5s ease-in-out, left 0s .5s ease

    .tour__item
      display: flex
      align-items: center
      justify-content: center
      flex-wrap: wrap
      flex-direction: column
      z-index: 105

      &::before
        display: block

  &__pane
    +mq(tablet_xl)
      overflow-y: hidden

    .tour__item
      .tour__item-content
        +mq(tablet_xl)
          display: none

  &__content
    +mq(tablet_xl)
      position: relative
      width: fit-content

  &__intro
    margin: 35px

    +mq(tablet_xl)
      display: flex
      align-items: center
      justify-content: center
      box-sizing: border-box
      position: absolute
      margin: 0
      top: 0
      left: -100%
      bottom: 0
      width: 100%
      color: c(green, l)
      opacity: 0
      z-index: 105
      transition: opacity .5s ease-in-out, left 0s .5s ease

      p
        color: c(green, l)

      &::after
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 103
        background-color: rgba(0, 0, 0, .75)

  &__intro-content
    +mq(tablet_xl)
      position: relative
      width: 70%
      max-width: 800px
      z-index: 104

  &__intro-title
    +mq($until: tablet_xl)
      font-size: 2.125em !important
      line-height: 1.117647059

    +mq(tablet_xl)
      font-size: 3em !important
      line-height: 1

    +mq(desktop)
      font-size: 4em !important

  &__intro-text
    p
      +u-font(18, 25)
      +f_bold

      +mq(desktop)
        +u-font(20, 28)

  &__intro-credits
    +u-font(13, 18)
    +f_bold
    overflow: hidden

    dt
      display: inline-block
      float: left
      clear: left
      margin-right: .5em

    dd
      +f_medium
      float: left
      text-transform: uppercase
      letter-spacing: .1em

  &__intro-tutorial
    display: none

    +mq(tablet_xl)
      display: block
      margin-top: 25px
      cursor: pointer
      position: relative
      padding-bottom: calc(522.63 / 1989 * 100%)
      overflow: hidden
      max-width: 100%
      background: black

      > iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

  &__intro-skip
    display: none
    width: 40px
    height: 40px
    border-radius: 20px
    background: c(green, l) url(~/assets/images/tour/skip.svg) no-repeat center / 21px 24px
    cursor: pointer

    +mq(tablet_xl)
      right: -10%
      top: 50%
      position: absolute
      z-index: 107
      animation: 2s 5s infinite alternate breathing

  &__back-button
    display: none
    width: 40px
    height: 40px
    border-radius: 20px
    background: c(green, l) url(~/assets/images/tour/back.svg) no-repeat 8px center / 21px 24px
    cursor: pointer

    +mq(tablet_xl)
      display: block
      left: 48px
      position: absolute
      top: 48px
      z-index: 101

  &__wallpaper

    +mq($until: tablet_xl)
      margin: 60px 0 45px

    +mq(tablet_xl)
      min-width: 100%

    &-image
      display: block
      height: calc(100vh - 80px)
      max-height: 450px
      width: auto

      +mq(tablet_xl)
        min-width: 101%
        min-height: 600px
        max-height: none

      +mq(tablet_ls)
        height: calc(100vh - 150px)

      +mq(laptop)
        min-height: 720px
        height: calc(100vh - 180px)

      +mq(super_wide)
        height: calc(100vh - 240px)

  &__item
    padding: 4px 0

    +mq(tablet_xl)
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      padding: 0

  &__toggle
    position: relative
    display: block
    padding: 10px 45px 10px 10px
    margin: 0 35px
    +u-font(20, 26)
    +f_bold
    background: #000
    color: #fff
    cursor: pointer
    z-index: 102

    +mq(tablet_xl)
      position: absolute
      padding: 10px
      margin: 0
      opacity: 1
      transition: opacity .3s ease-in-out

    +mq(laptop)
      padding: 4px 10px 3px
      +u-font(30, 35)

    &::after
      content: ''
      position: absolute
      right: 8px
      top: 8px
      width: 30px
      height: 30px
      border-radius: 20px
      background: c(green, l) url(~/assets/images/tour/plus.svg) no-repeat center / 16px 16px
      transition: transform .4s ease-in-out

      +mq(tablet_xl)
        right: -16px
        top: -16px

      +mq(laptop)
        right: -22px
        top: -22px
        width: 40px
        height: 40px
        background-size: 22px 22px

    &:hover, &:focus, &:active
      &::after
        background-image: url(~/assets/images/tour/plus-white.svg)

  &__item-content
    position: relative
    opacity: 0

    +mq($until: tablet_xl)
      top: -30px
      max-height: 0
      overflow: hidden
      margin-top: -20px
      margin-bottom: 20px
      z-index: 101
      transition: opacity .5s ease-in-out, top .2s ease-in-out, max-height .5s ease-in-out

    +mq(tablet_xl)
      width: 92%
      min-height: 550px
      display: block

    +mq(desktop)
      width: 90%

    +mq(wide)
      width: 85%

    +mq(laptop)
      width: 70%

  &__item-close
    display: none
    content: ''
    width: 30px
    height: 30px
    text-indent: -999em
    background: url(~/assets/images/tour/plus.svg) no-repeat center / 18px 18px
    cursor: pointer
    transform: rotate(45deg)

    +mq(tablet_xl)
      display: block

  &__item-headline
    display: none

    +mq(tablet_xl)
      display: block

  &__item-text
    +u-font(16, 22)
    +f_medium

    +mq($until: tablet_xl)
      padding: 45px 35px 35px
      background-color: c(green, l)

  &__item-scroll-pane
    +mq(tablet_xl)
      margin-right: -34px
      padding-right: 34px
      max-height: calc(55vh - 66px)

    +mq(desktop)
      max-height: calc(60vh - 66px)

  &__item-image
    padding: 30px 35px 25px

    > img
      display: block
      padding: 0

    > figcaption
      margin: 20px 0 0
      +u-font(13, 18)
      +f_medium
      color: c(green, l)

    +mq(tablet_xl)
      padding: 0 15px

      > figcaption
        display: none

  &__item-caption
    display: none
    +u-font(13, 18)
    +f_medium
    color: c(green, l)

    +mq(tablet_xl)
      display: block

  &__item-factsheet
    background-color: #000
    padding: 35px

    > img
      display: block
      width: 100%

    +mq(tablet_xl)
      position: absolute
      bottom: 0
      right: 0
      width: calc(30% + 40px)
      z-index: 105

  &__item-factsheet-info
    display: flex
    align-items: flex-end
    margin-top: 15px

    > img
      width: 45%
      padding: 0 10px 0 0

    > figcaption
      +u-font(16, 24)
      +f_bold
      width: 55%
      padding-left: 10px
      margin: 0
      color: c(green, l)

      +mq(tablet_xl)
        +u-font(12, 18)

      +mq(tablet_ls)
        +u-font(14, 21)

      +mq(desktop)
        +u-font(16, 24)


// Modifier

%item--standard
  .tour__item-content
    +mq(tablet_xl)
      display: block

  .tour__item-headline
    padding: 4px 10px 3px
    margin-bottom: 0
    +u-font(30, 35)
    +f_bold
    background: #000
    color: #fff

    +mq(tablet_xl)
      position: absolute
      left: -20px
      top: -21px

    +mq(wide)
      left: -30px

    +mq(laptop)
      left: -60px

  .tour__item-close
    position: absolute
    top: 8px
    left: calc(70% - 40px)

  .tour__item-text
    +mq(tablet_xl)
      margin-bottom: 5vh
      width: 70%
      padding: 45px 50px 20px 35px
      background-color: c(green, l)

      p
        +u-font(14, 21)

    +mq(desktop)
      margin-bottom: 10vh

    +mq(laptop)
      p
        +u-font(16, 24)

%item--concise
  &::before
    +mq(tablet_xl)
      display: none
      content: ''
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      z-index: 103
      background-color: rgba(0, 0, 0, .75)

  .tour__item-content
    +mq(tablet_xl)
      display: flex
      flex-direction: row-reverse
      align-items: flex-end
      align-content: center
      flex-wrap: wrap
      justify-content: center
      z-index: 105

  .tour__item-headline
    +u-font(25, 30)
    +f_bold
    text-transform: uppercase
    color: c(green, l)
    margin-bottom: 20px

  .tour__item-close
    +mq(tablet_xl)
      align-self: flex-start
      background-image: url(~/assets/images/tour/plus-green.svg)

  .tour__item-text
    +mq(tablet_xl)
      box-sizing: border-box
      width: calc(58% - 27px)
      padding: 0 15px
      max-width: 520px

    p
      +mq(tablet_xl)
        +u-font(16, 24)
        color: c(green, l)

      +mq(wide)
        +u-font(20, 28)

  .tour__item-image
    background-color: #000

    +mq(tablet_xl)
      box-sizing: border-box
      width: calc(42% - 27px)
      background-color: transparent

      > img
        max-width: 380px
        display: block

.tour--loading
  .tour__item, .tour__back-button
    +mq(tablet_xl)
      display: none

.tour--focussed
  .tour__toggle
    +mq(tablet_xl)
      opacity: 0

  .tour__back-button
    display: none

  .tour__focus-content
    left: 0
    opacity: 1
    transition: opacity .5s ease-in-out

.tour--intro
  .tour__intro
    left: 0
    opacity: 1
    transition: opacity .5s ease-in-out

  .tour__item
    +mq(tablet_xl)
      display: none

  .tour__intro-skip
    +mq(tablet_xl)
      display: block

  .tour__back-button
    display: none

  .tour__toggle
    +mq(tablet_xl)
      opacity: 0

.tour__item--europe
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 34%
      right: 47.5%
  .tour__item-text
    +mq(tablet_xl)
      max-height: 55vh

    +mq(desktop)
      max-height: 60vh

.tour__item--system
  @extend %item--concise
  .tour__toggle
    +mq(tablet_xl)
      top: 71%
      right: 45%

.tour__item--expert
  @extend %item--concise
  .tour__toggle
    +mq(tablet_xl)
      top: 82%
      right: 65.3%

.tour__item--wind
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 14%
      right: 23%

.tour__item--innovation
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 52.5%
      right: 29%

.tour__item--net
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 53%
      right: 83%

.tour__item--tasks
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 25%
      right: 5.3%

.tour__item--balance
  @extend %item--standard
  .tour__toggle
    +mq(tablet_xl)
      top: 38.5%
      right: 16%

.tour__item--active
  .tour__toggle
    &::after
      +mq($until: tablet_xl)
        background-color: transparent
        transform: rotate(45deg)
        background-image: url(~/assets/images/tour/plus-green.svg)

      +mq(tablet_xl)
        display: none

  .tour__item-content
    opacity: 1

    +mq($until: tablet_xl)
      max-height: 5000px
      top: 0



// Simplebar overwrites

.simplebar-track
  z-index: 101
  border-radius: 8px
  box-shadow: 3px 0px 6px #00000029
  background-color: rgba(0, 0, 0, .5)

  .simplebar-scrollbar
    border-radius: 6px

    &::before
      background-color: c(green, l)
      box-shadow: 3px 3px 6px #00000038
      opacity: 1

  &.simplebar-horizontal
    bottom: 10px
    left: 25px
    right: 25px
    height: 16px

    +mq(tablet_xl)
      bottom: 18px
      left: 115px
      right: 115px

    .tour--intro &, .tour--focussed &
      bottom: -50px !important

    .simplebar-scrollbar
      height: 12px

  &.simplebar-vertical
    right: 0px
    top: 25px
    bottom: 25px
    width: 16px

    .simplebar-scrollbar
      width: 12px

      &::before
        width: 100%

@keyframes breathing
  0%
    box-shadow: 0 0 0 0 rgba(c(green, l), 0)
    transform: scale(1)
  80%
    transform: translate(0, 0)
  90%
    transform: translate(2px, 0)
  100%
    transform: translate(0, 0)
    box-shadow: 0 0 10px 5px rgba(c(green, l), .85)
