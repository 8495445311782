$lineCounterSize: 80px
$dotSize: 30px
$dashedBorderWidth: 4px
$lineIndentation: ($lineCounterSize - $dashedBorderWidth) / 2
$dotIndentation: ($lineCounterSize - $dotSize) / 2

.pathway
  position: relative

.pathway__canvas
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  text-align: center

.pathway__block
  +content-mq()
  position: relative
  height: 100%
  margin: 0 auto
  z-index: -1

.pathway__lane
  position: absolute
  box-sizing: border-box
  top: 0
  bottom: 0
  left: calc(10px + #{$gutter-small / 2})
  right: calc(10px + #{$gutter-small / 2})

  +mq(phablet)
    left: calc(100px + #{$gutter-small / 2})
    right: calc(100px + #{$gutter-small / 2})

  +mq(tablet)
    left: calc(50% + #{$gutter-large / 2})
    width: calc(50% - #{$gutter-large * 1.5})

  &::before
    content: ''
    position: absolute
    top: 800px
    bottom: 0
    left: 0
    border-right: $dashedBorderWidth dashed black
    width: $lineIndentation

    +mq(tablet)
      top: 200px
      width: calc(15% + #{$lineIndentation})

.pathway__dot
  background-color: #9E007E
  width: $dotSize
  height: $dotSize
  position: absolute
  top: 800px
  left: $dotIndentation
  border-radius: 50%
  transition: top 2s ease-in-out

  +mq(tablet)
    top: 200px
    left: calc(15% + #{$dotIndentation})
