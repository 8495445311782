.forest__02
  opacity: 0.3
  transform: scale(1.25)
  +mq($from: tablet)
    margin-bottom: -40vh
    margin-top: -20vh

  .u-vivus
    right: -30%

.u-vivus
  svg
    +mq($until: desktop)
      width: 100%
      height: auto