body

  font-size: 100%
  +f_regular
  text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-rendering: optimizeLegibility
  min-width: 20rem

  ::selection
    background: c(primary, base)

  // mq debug
  &:before
    z-index: 999
    right: auto
    left: 0

  +mq($until: desktop)
    overflow-x: hidden

*
  scroll-behavior: smooth
  box-sizing: border-box
  margin: 0
  padding: 0

::selection
  background: c(primary)
  color: c(white)

#uc-full-optin-description
  color: black !important

img
  width: 100%
  height: auto

img, a
  border: none
  outline: none

iframe
  border: none

button
  border: none

.icon-animated
  opacity: 0
  transition: opacity 0.2s

  img
    display: none

  &.is-ready
    opacity: 1

  .icon
    svg
      display: block
      width: 100%
      height: 100%

  +mq($until: desktop)
    svg
      display: none
    img
      display: block


.section--100vh
  height: 100vh

.section--bg-grey
  background-color: c(grey, l)

  +mq($until: desktop)
    margin-bottom: 100px !important

.section--bg-yellow
  background-color: c(primary)

.section--yellow-white
  background: #F5A623

.section--neutral
  background: rgba(c(grey, l), .55)

.yellow
  color: c(primary)

.image__reveal
  overflow: hidden

  img
    animation: image_reveal 1.2s forwards
    animation-delay: 0.3s
    transform: scale(1)
    opacity: 0

  @keyframes image_reveal
    0%
      transform: scale(1)
      opacity: 0
    100%
      transform: scale(1.2)
      opacity: 1

.content__wrapper
  margin: auto
  overflow: hidden

  +mq($until: phablet)
    margin-top: 60px

.mobile__item
  display: none
  +mq($until: tablet)
    display: block
.desktop__item
  display: block
  +mq($until: tablet)
    display: none

.section--grey
  background: c(grey,l)
  max-width: 1280px
  margin: auto
  &__white
    padding-top: 40px
    background: c(white)


.section--grey__white
  padding: 40px !important

.image-caption
  padding-top: 15px
  font-size: 12px
  line-height: 18px

.mobile--hidden
  display: block
  +mq($until: tablet)
    display: none !important

.mobile--display
  +mq($from: tablet)
    display: none !important

.tablet-xl--hidden
  +mq($from: tablet_xl)
    display: none!important

.desktop--hidden
  +mq($from: tablet)
    display: none!important

#animate
  position: relative
  top: -100vh

.video
  position: relative
  padding-bottom: 56.25%
  overflow: hidden


.video iframe,
.video object,
.video embed
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.text-small
  font-size: .8rem

//background images overflowing container
.bg--overflow
  position: relative

  &.overflow-bottom
    &::before
      background-repeat: no-repeat
      bottom: -5rem
      content: ' '
      height: 100%
      left: 20%
      top: 70px
      position: absolute
      width: 100vw
      z-index: -1

.abs
  position: absolute

.di
  display: inline

.dib
  display: inline-block

.desktop
  +mq($until: tablet_ls)
    display: none

.ma
  margin: auto
  position: relative

.tablet
  display: none
  +mq($until: tablet_ls)
    display: block

.mobile
  display: none
  +mq($until: phablet)
    display: block

.anchor
  top: -250px
  display: block
  visibility: hidden
  padding-top: 100px
  margin-top: -100px


#top-link
  position: fixed
  right: 0px
  top: 80%
  width: 40px
  height: 40px
  background: c(cyan, base)
  border-radius: 15px 0px 0px 0px
  transition: all 0.2s ease-in-out
  z-index: 999
  &:hover
    width: 45px
  &:before
    content: ''
    width: 15px
    height: 15px
    display: block
    border-top: 3px white solid
    border-left: 3px white solid
    transform: rotate(45deg)
    position: absolute
    top: 15px
    left: 11px
  +mq($until: phablet)
    top: 80%
    right: -4px



.scroll--hide
  transition: all 0.3s ease-in-out


  &.is-hidden
    opacity: 0 !important
  &__container
    max-width: 1300px
    padding: 0px 50px
    width: 100%
    left: 50%
    height: 300px
    top: 400px
    transform: translateX(-50%)
    position: absolute
  h1
    position: absolute
    bottom: 0px
    left: 100px
    // z-index: -1
    font-size: 36px !important
    +mq($until: desktop)
      display: none !important



  h1
    transition: all 0.2s ease-in-out
  &.headline-active
    h1
      bottom: -239px
      +mq($until: laptop)
        bottom: -202px
      +mq($until: wide)
        bottom: -102px



  &.scroll--hide--report
    h1
      left: unset
      right: 100px



.slider-further,
.slider-further__corporate
  +mq($until: tablet)
    padding: 0px 40px !important
    .slick-prev
      left: 50px
    .slick-next
      right: 3px
  +mq($until: phablet)
    padding: 0px
    .slider-further__img
      padding: 0px

    .slick-prev
      left: 0px
    .slick-next
      right: 0px

.section
  &.mobile--marbot
    +mq($until: phablet)
      margin-bottom: 30px !important

.special
  +mq($until: phablet)
    .highlight,
    .highlight--sub
      transform: translateX(80px)
    .highlight--sub
      margin-top: 8rem !important
      max-width: 250px !important
    .high
      line-height: 38px !important



.scroll--hide__container--1
  margin-top: 30px

