.icon
  &--hero
    padding: 15px
    transform: rotate(90deg)
    margin: auto

    +mq($until: tablet)
      padding-top: 1rem

      // svg
      //   height: 1.875rem
      //   width: 1.875rem

  &--teaser
    position: absolute
    right: 2rem
    bottom: 0

    +mq($until: desktop)
      position: relative
      left: 0rem
      svg
        margin-top: 1.25rem
        height: 1.875rem
        width: 1.875rem

    &__12
      position: relative
      padding-top: 2rem
      right: 0
      +mq($until: tablet)
        padding-top: 0




