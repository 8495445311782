.home
  position: relative
  overflow: hidden
  border-radius: 0px 0px 65px 0px
  +mq($from: tablet )
    top: 80px
    content: ""
    display: block
    width: 100%
    max-width: 1440px
    //min-height: 470px
    //max-height: 480px
    margin-left: auto
    margin-right: auto
    margin-bottom: 12rem

  //+mq($from: desktop)
    min-height: 650px
    max-height: 660px

  +mq($until: phablet)
    margin-bottom: 60px
  +mq($from: phablet, $until: tablet)
    margin-top: 80px

  img
    border-radius: 0px 0px 65px 0px

  &__gallery
    +mq($until: phablet)
      img
        width: 100%

  &__button
    width: 50px
    height: 50px
    position: absolute
    bottom: 75px
    left: calc(50% - 25px)
    background: c(white)
    border-radius: 50%
    cursor: pointer

    +mq($until: tablet)
      // bottom: 0px
      // z-index: 3
      display: none

    &:after
      content: ''
      display: block
      width: 13px
      height: 13px
      border-top: 3px solid c(primary, base)
      border-right: 3px solid c(primary, base)
      transform: rotate(135deg)
      position: relative
      left: 17px
      top: 10px
      animation: arrow_updown 2s ease-in-out infinite

      @keyframes arrow_updown
        0%
          top: 10px
        50%
          top: 16px
        100%
          top: 10px

  &__gradient
    width: 100%
    position: absolute
    left: 0
    top: 30%
    bottom: 0
    right: 0
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)

    +mq($until: tablet)
      bottom: 6px
      top: 30%

    // &::after
    //   content: ''
    //   width: 100%
    //   background: white
    //   height: 50px
    //   display: block
    //   position: absolute
    //   bottom: 0
    //   +mq($until: tablet)
    //     display: none


  &__quicklinks
    display: flex
    justify-content: space-between

    +mq($until: tablet)
      display: block

    &-item
      display: block
      background: c(white)
      border: 1px solid c(grey, dd)
      width: calc(33.3% - 15px)
      padding: 20px
      position: relative
      min-height: 156px

      +mq($until: tablet)
        width: 100%
        // margin-bottom: 20px
        min-height: 120px

      &:after,
      &:before
        content: ''
        background-color: c(grey, dd)
        position: absolute
        right: 18px
        width: 30px
        height: 2px
        display: block
        bottom: 33px
        transition: all .3s ease-in-out

      &:before
        transform: rotate(90deg)

      &:hover
        border: 1px solid c(primary, base)
        transition: all 0.3s ease-in-out
        span
          color: c(primary, base)
          transition: all 0.3s ease-in-out
          &:after
            background: c(primary, base)

      span
        +f_superbold
        display: block
        position: relative
        font-size: 24px
        &:after
          content: ''
          position: absolute
          width: 21px
          height: 4px
          display: block
          background: c(black)
          top: 32px
          left: 0px
          transition: all 0.3s ease-in-out
          +mq($until: tablet)
            position: relative
            top: 0

      p
        font-size: 18px
        padding-top: 15px
        color: c(grey, dd)

  &__management
    margin-top: 25px
    margin-bottom: 25px
    background-image: url(~/assets/images/00_index/vorstand_teaser.jpg)
    background-size: cover
    background-position-x: right
    width: 100%
    height: 550px
    color: c(white)
    position: relative
    display: block

    +mq($until: desktop)
      height: 300px

    +mq($until: phablet)
      height: 250px
      background-position-x: 75%
      .home__management-title
        span
          &:after
            display: none

    &-gradient
      height: 50%
      width: 100%
      position: absolute
      bottom: 0px
      background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)

    &-title
      position: absolute
      bottom: 75px
      left: 30px

      +mq($until: tablet)
        bottom: 60px

      span
        +f_superbold
        font-size: 40px
        display: block
        +mq($until: tablet)
          font-size: 30px

        &:after
          content: ''
          position: absolute
          width: 32px
          height: 7px
          display: block
          background: c(white)
          top: 55px
          left: 2px
          transition: all 0.3s ease-in-out
          +mq($until: tablet_ls)
            top: 42px
            height: 5px
            width: 25px


    &-sub
      position: absolute
      bottom: 30px
      left: 30px
      +mq($until: tablet)
        bottom: 20px
      span
        font-size: 18px
        +f_regular
        color: c(white)
        +mq($until: tablet)
          font-size: 16px


  &__downloads
    display: flex
    justify-content: space-between

    +mq($until: tablet)
      display: block

    a
      display: block
      background: c(grey, dd)
      color: c(grey, l)
      width: calc(33.3% - 15px)
      text-align: center
      padding: 20px
      text-transform: uppercase
      +f_bold
      font-size: 18px

      svg
        height: 22px


      +mq($until: tablet)
        width: 100%
        margin-bottom: 20px

  &__image--1
    float: right
    padding-bottom: 30px



  &__parallax
    .layer
      &--1, &--2, &--3
        img
          transform: scale(1.05)

.home__layer
  // TEXT
  &--title
    position: absolute
    top: 48px
    width: 100%
    z-index: 4

    +mq($until: phablet)
      top: 18px

    h1
      margin-left: 100px
      margin-bottom: 60px

    +mq($until: desktop)
      padding-left: 3em

    +mq($until: tablet_ls)
      padding-left: 2em

    +mq($until: phablet)
      padding-left: 1em
      h1
        margin-left: 30px

    +mq($until: tablet)
      bottom: 10%

      .title__meta
        font-size: 14px
        margin-bottom: 0px

    &__wrapper
      max-width: 1060px
      margin: auto
      h1, span
        text-shadow: 2px 2px 17px rgba(0,0,0,0.98)
      h1.line
        display: block
        font-size: 106px

        +mq($until: desktop)
          font-size: 72px !important

          &:after
            width: 60px
            height: 12px

        +mq($until: phablet)
          font-size: 42px !important
          &:after
            width: 40px
            height: 8px

// Mobile Fix
.home__mobile
  img
    margin-bottom: 0
