.profile

  &--item
    margin-bottom: 20px

    +mq($until: tablet)
      width: 50% !important

  &--site
    width: 100%

  &--number
    width: 25%
    display: inline-block
    padding-right: 10px
    span
      display: block
      background-color: c(primary, 300)
      color: c(grey, d)
      width: 30px
      height: 30px
      text-align: center
      border-radius: 20px
      +f_bold
      font-size: 18px
      line-height: 30px

  &--text
    width: 70%
    display: inline-block
    vertical-align: top
    padding-bottom: 20px
    font-size: 18px