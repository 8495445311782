.interview
  &__answer
    margin-bottom: 2.5rem

  &__author
    display: inline-block
    +f_bold
    font-size: 1rem
    margin-right: .25rem

  &__question
    +f_bold
