table
  width: 100%
  border-spacing: 0
  border-collapse: collapse

  tr
    &:hover
      background: c(grey, l)
    &.active
      td, th
        +f_bold
        text-transform: uppercase
        border-bottom: 2px solid c(grey,d)

  td,th
    +u-font(16, 20)
    border-spacing: 0px
    padding: 8px 0px

    &.clear
      border-bottom: 0px

    &.active
      border-right: 2px solid c(primary, base)
      border-left: 2px solid c(primary, base)

    &.active--top
      border-top: 2px solid c(primary, base)

    &.active--bottom
      border-bottom: 2px solid c(primary, base)


  th
    text-align: left


  thead
    th
      border-bottom: 2px solid c(grey, md)
      padding-right: 10px

  tbody
    tr
      th,td
        border-bottom: 1px solid c(grey, md)

    td,th
      +f_regular

    td
      text-align: right
      padding:
        left: 10px
        right: 10px

.table__wrapper
  +mq($until: phablet)
    overflow-x: scroll