.balken-svg--wrapper
  width: 100%
  .balken-svg
    width: auto
    height: 30px
    &--1
      margin-bottom: 10rem
    &--2
      margin-bottom: 10rem
      img
        width: 70%




