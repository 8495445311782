.text-grafic


  &__text
    p
      color: c(white, 500)

  &__grafic
    position: relative
    svg
      width: 466px
      height: 604px
      position: absolute
      left: 7%
      top: -200px
      width: 75%

      +mq($until: tablet)
        position: relative
        top: 0px