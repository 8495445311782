.image-quote
  &__bottom
    width: 100%
    &--left
      position: relative
      span
        position: absolute
        right: 20px
        font-size: 18px
        line-height: 25px
        padding-left: 30px
        +mq($until: tablet)
          position: relative
          padding-left: 0px
          margin-bottom: 30px
          display: block
    &--right
      position: relative
      span
        position: absolute
        right: 30px
        top: 365px
        font-size: 18px
        line-height: 25px
        padding-left: 30px
        +mq($until: desktop)
          top: 235px

        +mq($until: tablet)
          position: relative
          padding-left: 0px
          margin-bottom: 30px
          display: block
          top: 15px

  &__top
    width: 100%
    position: relative
    min-height: 460px
    +mq($until: desktop)
      padding-top: 100px


    &--right
      blockquote
        bottom: 100px

      cite
        width: 100%
        display: block
        bottom: -55px
        +f_regular
        font-style: normal

      blockquote,
      cite
        position: absolute
        +mq($until: tablet)
          position: relative
          bottom: 0px
          padding-top: 30px

.image-quote
  width: 80%
  .sub
    margin-bottom: .5rem
    text-transform: uppercase
  .sub, .sub-line
    font-size: 13px

  &.quote__bottom
    margin-top: 1.5rem