.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 99999

  &__title,
  &__menu
    +f_bold
    width: auto
    +mq($until: phablet)
      left: 0

    span, a
      +f_bold
      font-size: 18px
      letter-spacing: 1px
      margin-left: 10px
      color: c(cyan, base)

      .arrow
        border-color: c(cyan, base)

  &__menu
    opacity: 0
    pointer-events: none
    transform: translateY(-10px)
    transition: all 0.3s ease-in-out


    &.active
      opacity: 1
      transform: translateY(0px)
      pointer-events: initial

  &__title
    padding-top: 30px
    i
      transition: all 0.3s ease-in-out
      position: relative
      top: 0px
    &.active
      i
        transform: rotate(225deg)
        top: 8px


    // pointer-events: none
    +mq($until: phablet)
      padding-top: 19px


    span
      transition: all 0.5s ease-in-out
      +f_bold

      +mq($until: desktop)
        padding-left: 15px

      +mq($until: phablet)
        padding-left: 0px
        font-size: 12px

    &:hover
      span
        color: c(cyan, base)
        cursor: pointer

  &__menu
    position: absolute
    background: #fff
    z-index: 99999
    padding: 20px 0px

    +mq($until: phablet)
      width: 100%

    @media print
      display: none

    a
      display: block
      padding-top: 20px
      height: 65px
      color: c(black)
      position: relative
      transition: all .3s ease-in-out
      border-bottom: 3px solid c(black)
      margin-left: 20px
      margin-right: 20px

      +mq($until: phablet)
        font-size: 16px
        height: 50px

      &:last-child
        border-bottom: none


      // &:after,
      // &:before
      //   content: ''
      //   background-color: c(black)
      //   position: absolute
      //   right: 10px
      //   width: 10px
      //   height: 2px
      //   display: block
      //   top: 33px
      //   transition: all .3s ease-in-out


      &:before
        transform: rotate(-90deg)

      &:hover
        color: #00A9E0

      &.active
        background: c(cyan)
        color: c(white)

        &:after
          background-color: c(white)
          transform: rotate(45deg)

        &:before
          background-color: c(white)
          transform: rotate(-45deg)

    &__link--1, &__link--2, &__link--3, &__link--4
      +mq($until: tablet_ls)
        display: none !important


  &__logo
    padding: 20px
    width: 150px
    // background: c(primary, base)
    height: 65px
    position: absolute
    right: 0px
    top: 0px
    transition: 0.3s all ease-in-out
    +mq($until: phablet)
      width: 60px
      img
        float: right


    img
      height: 50px
      width: auto
      transition: 0.3s all ease-in-out

      +mq($until: phablet)
        height: 30px

    .header.scrolling &
      padding: 15px
      height: 50px
      transition: 0.3s all ease-in-out

      img
        height: 40px
        transition: 0.3s all ease-in-out

.header.scrolling > .header-wrapper
  height: 65px
  transition: 0.3s height ease-in-out

.header-wrapper
  left: auto
  opacity: 1
  height: 80px
  background-color: c(white)
  max-width: 100%
  border-bottom: 1px solid c(grey, d)
  transition: 0.3s height ease-in-out

  +mq($until: phablet)
    height: 61px


.header-content
  height: 100%
  max-width: 1440px
  margin: auto
  position: relative
  z-index: 99999
  background-color: c(white)

  // &:hover
  //   .header__menu
  //     display: block


.header-logo
  max-width: 150px
  display: inline-block
  margin-left: 25px

  img
    width: 150px
    +mq($until: phablet)
      width: 100px
      padding-top: 10px

.header__languageswitch
  position: absolute
  right: 170px
  top: 30px
  +f_bold

  +mq($until: phablet)
    right: 60px
    top: 23px

  &:hover
    a
      color: #00A9E0 !important
  a
    color: #000 !important

.header__home
  img
    width: 25px
    right: 230px
    position: absolute
    top: 25px
    +mq($until: phablet)
      right: 100px
      top: 20px
      width: 25px


.header__menu__link--mobile
  width: 100%
  +mq($from: tablet_ls)
    display: none !important


.logo__desktop
  display: block
  +mq($until: phablet)
    display: none

.logo__mobile
  display: block
  +mq($from: phablet)
    display: none