.number
  +mq($from: tablet)
    padding-right: 0 !important

  &--cyan
    .countup-number,
    .numbers--unit-sub
      color: #9e007e

  &__item
    +u-font(39, 39)
    display: block
    width: 4rem
    height: 4rem
    border-radius: 50%
    background-color: c(primary)
    color: c(white)
    text-align: center
    padding-top: .38em

    +mq($until: tablet)
      +u-font(30, 30)
      height: 3rem
      width: 3rem
      padding-top: .7rem
      margin-bottom: 1rem

    +mq($until: phablet)
      +u-font(25, 25)
      height: 2.5rem
      width: 2.5rem
      padding-top: .5rem
      margin-bottom: .5rem

.numbers
  +f-bold
  &--98
    +f-bold
    font-size: 98px
    color: c(cyan, base)
    font-weight: bold
    +mq($until: tablet_ls)
      font-size: 70px
    +mq($until: phablet)
      font-size: 58px
  &--140
    +f-bold
    font-size: 140px
    color: c(cyan, base)
    font-weight: bold
  &--160
    +f-bold
    font-size: 120px
    color: c(cyan, base)
    font-weight: bold
  &--200
    +f-bold
    font-size: 200px
    color: c(cyan, base)
    font-weight: bold
    +mq($until: phablet)
      font-size: 120px


  &--unit
    font-size: 32px
    color: c(cyan, base)
    font-weight: bold
  &--unit-sub
    +f-bold
    font-size: 22px
    color: c(cyan, base)
    font-weight: bold
    text-transform: uppercase
  &--subline
    font-size: 20px
    +f_bold
  &--subline-bold
    +f-bold
    font-size: 20px
    font-weight: bold



.countup-number
  +mq($until: phablet)
    font-size: 58px !important

