.hero
  height: 650px
  background: grey
  position: relative
  max-width: 1440px
  margin: auto
  margin-bottom: 100px
  background-color: c(black)
  overflow: hidden

  +mq($until: tablet)
    height: 400px

  +mq($until: phablet)
    height: 200px
    margin-bottom: 50px


  &__gradient
    height: 350px
    width: 100%
    position: absolute
    bottom: 0px
    background: linear-gradient(to top, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%)

    +mq($until: phablet)
      height: 200px

  &__bg
    margin-top: 65px
    height: 600px
    background-size: cover
    background-position: center
    transition: all
    img
      border-radius: 0px 0px 65px 0px

    +mq($until: desktop)
      height: 100%
    +mq($until: phablet)
      margin-top: 0px

  &__content
    position: absolute
    bottom: 65px
    width: 100%

    +mq($until: tablet)
      bottom: 30px
    +mq($until: desktop)
      padding-left: 20px
    +mq($until: phablet)
      bottom: 30px
      padding-right: 20px

  &__meta,
  &__title
    color: c(white)
    max-width: 1060px
    margin: auto

    h1
      +mq($until: tablet)
        font-size: 20px !important

      +mq($until: desktop)
        font-size: 40px !important

    &--line
      position: relative
      &:after
        content: ''
        width: 40px
        height: 8px
        background: c(white)
        position: absolute
        bottom: -20px
        left: 0px

        +mq($until: tablet)
          width: 20px
          height: 4px
          bottom: -10px

  &__meta
    span
      &:first-child,
      &:last-child
        display: inline-block
        font-size: 20px
        text-transform: uppercase

        +mq($until: tablet)
          font-size: 12px

        +mq($until: desktop)
          font-size: 16px

      &:last-child
        +f_regular

      &:first-child
        +f_bold
        padding-right: 15px
        position: relative
        &:after
          content: ''
          width: 1px
          height: 14px
          background: c(white)
          display: inline-block
          position: relative
          left: 7px

.fixed
  position: fixed !important
  z-index: 0

