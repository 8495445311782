.box
  border: 30px solid c(green, l)
  width: 90%
  margin: auto
  max-width: 1400px

  +mq($until: desktop)
    iframe
      width: 100% !important

  &--transparent
    padding: 30px
    background: rgb(255,255,255, 0.6)

  h4
    +u-font(20, 26)
    color: c(green, l)
    text-transform: uppercase

  h4.smaller
    +u-font(16, 20)
    color: #000
    text-transform: uppercase

  &--border-purple-berry
    border: 4px solid c(purple, berry)
    padding: 2rem

  &--border-black
    border: 4px solid c(black)

  &--border-dotted
    background-color: c(white)
    border: 4px dashed c(black)
    padding: 30px
    width: 100%

    +mq(tablet)
      width: 90%

.textbox
  p.stronk
    letter-spacing: 0.25em

  &--turquoise
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    background: rgba(0, 176, 151, 0.9)
    padding: 25px 25px 10px 25px
    +f_medium
    p
      color: #fff
      +u-font(13, 16)
      +f_medium

  &--purple
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    background: c(purple, base)
    opacity: 0.7
    padding: 25px 25px 10px 25px
    p
      color: #fff
      +u-font(13, 16)
      +f_medium

