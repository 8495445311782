$base-space: 1rem

.u-padtop
  @for $i from 1 through 100
    &--#{$i}
      padding-top: $i * $base-space
      +mq($until: tablet_ls)
        padding-top: $i * $base-space / 2!important
    @each $bp, $size in $mq-breakpoints
      &-#{$bp}--#{$i}
        +mq($from: $bp)
          padding-top: $i * $base-space!important

.u-padbot
  @for $i from 1 through 100
    &--#{$i}
      padding-bottom: $i * $base-space
      +mq($until: tablet_ls)
        padding-bottom: $i * $base-space / 2!important
    @each $bp, $size in $mq-breakpoints
      &-#{$bp}--#{$i}
        +mq($from: $bp)
          padding-bottom: $i * $base-space!important

.u-padleft
  @for $i from 1 through 100
    &--#{$i}
      padding-left: $i * $base-space
      +mq($until: tablet_ls)
        padding-left: $i * $base-space / 2!important
    +mq($from: tablet)
      &-tablet--#{$i}
        padding-left: $i * $base-space

.u-padright
  @for $i from 1 through 100
    &--#{$i}
      padding-right: $i * $base-space
      +mq($until: tablet_ls)
        padding-right: $i * $base-space / 2!important

    +mq($from: tablet)
      &-tablet--#{$i}
        padding-right: $i * $base-space


.u-padx
  @for $i from 0 through 100
    &--#{$i}
      padding-left: $i * $base-space!important
      padding-right: $i * $base-space!important
      +mq($until: tablet_ls)
        padding-left: $i * $base-space / 2!important
        padding-right: $i * $base-space / 2!important

.u-pady
  @for $i from 0 through 100
    &--#{$i}
      padding-top: $i * $base-space!important
      padding-bottom: $i * $base-space!important
      +mq($until: tablet_ls)
        padding-top: $i * $base-space / 2!important
        padding-bottom: $i * $base-space / 2!important

.u-inset
  @for $i from 1 through 100
    &--#{$i}
      padding: $i * $base-space

.u-martop
  @for $i from 0 through 100
    &--#{$i}
      margin-top: $i * $base-space!important
      +mq($until: tablet_ls)
        margin-top: $i * $base-space / 2!important

    @each $bp, $size in $mq-breakpoints
      &-#{$bp}--#{$i}
        +mq($from: $bp)
          margin-top: $i * $base-space!important

.u-marbot
  @for $i from 0 through 100
    &--#{$i}
      margin-bottom: $i * $base-space !important
      +mq($until: tablet_ls)
        margin-bottom: $i * $base-space / 2!important

    @each $bp, $size in $mq-breakpoints
      &-#{$bp}--#{$i}
        +mq($from: $bp)
          margin-bottom: $i * $base-space !important

.u-marleft
  @for $i from 0 through 100
    &--#{$i}
      margin-left: $i * $base-space
      +mq($until: tablet_ls)
        margin-left: $i * $base-space / 2!important

    +mq($from: tablet)
      &-tablet--#{$i}
        margin-left: $i * $base-space

.u-marleft-neg
  @for $i from 1 through 100
    &--#{$i}
      margin-left: -$i * $base-space
      +mq($until: tablet_ls)
        margin-left: -$i * $base-space / 2!important

    +mq($from: tablet)
      &-tablet--#{$i}
        margin-left: -$i * $base-space

.u-marright
  @for $i from 0 through 100
    &--#{$i}
      margin-right: $i * $base-space
      +mq($until: tablet_ls)
        margin-right: $i * $base-space / 2!important

    +mq($from: tablet)
      &-tablet--#{$i}
        margin-right: $i * $base-space

.u-marright-neg
  @for $i from 0 through 100
    &--#{$i}
      margin-right: -$i * $base-space!important

    +mq($from: tablet)
      &-tablet--#{$i}
        margin-right: -$i * $base-space!important

.u-martop-neg
  @for $i from 0 through 100
    &--#{$i}
      margin-top: -$i * $base-space
      +mq($until: tablet_ls)
        margin-top: -$i * $base-space / 2!important
    +mq($from: tablet)
      &-tablet--#{$i}
        margin-top: -$i * $base-space

.u-marbot-neg
  @for $i from 1 through 100
    &--#{$i}
      margin-bottom: $i * $base-space !important
      +mq($until: tablet_ls)
        margin-bottom: -$i * $base-space / 2!important
    +mq($from: tablet)
      &-tablet--#{$i}
        margin-bottom: -$i * $base-space!important

.u-marx
  @for $i from 0 through 100
    &--#{$i}
      margin-left: $i * $base-space!important
      margin-right: $i * $base-space!important
      +mq($until: tablet_ls)
        margin-left: $i * $base-space / 2!important
        margin-right: $i * $base-space / 2!important

.u-mary
  @for $i from 0 through 100
    &--#{$i}
      margin-top: $i * $base-space!important
      margin-bottom: $i * $base-space!important
      +mq($until: tablet_ls)
        margin-top: $i * $base-space / 2!important
        margin-bottom: $i * $base-space / 2!important

/**
 * translateX helper
 * @usage: u-trans--x-{0-100}
 * @usage: u-trans--x-{bp}--x-{0-100}
 */
.u-trans
  @for $i from 0 through 10
    &--x-#{$i*10}
      transform: translateX($i*10 * 1%)

    &-tablet--x-#{$i*10}
      +mq($from: tablet)
        transform: translateX($i*10 * 1%)

@mixin u-caps
  text-transform: uppercase
  letter-spacing: 0.2em

@mixin u-stretch($type: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0)
  position: $type
  top: $top
  right: $right
  bottom: $bottom
  left: $left

// simply enter font size and lineheight in px
@mixin u-font($fontsize: 16, $lineheight: 20)
  font-size: $fontsize + px
  font-size: $fontsize/ 16 + rem
  line-height: $lineheight / $fontsize + em


// $min-size: minimum element $property size in $unit specified
// $max-size: maximum element $property size in $unit specified
// $min-width: the screen width in $units where you want to lock in the $min-size
// $max-width: the screen width in $units where you want to lock in the $max-size
// $property: the property of the element that you would like to scale/lock
// $unit: the units which all measurements are taken in
// @mixin u-css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px)
//   #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})))

//   +mq($until: $min-width + 0px)
//     #{$property}: #{$min-size}#{$unit}

//   +mq($from: $max-width + 0px)
//     #{$property}: #{$max-size}#{$unit}


.u-nowrap
  white-space: nowrap

.u-big-target
  +u-stretch()
  display: block

.u-text-center
  text-align: center

.u-desktop
  +mq($until: tablet_ls)
    display: none

.u-desktop-60
  +mq($from: tablet_ls, $until: desktop)
    text-align: center
    img
      width: 60%
      margin: auto

.u-mobile-30
  +mq($until: phablet)
    text-align: center
    img
      width: 30%
      margin: auto

.u-padtop--10
  +mq($until: phablet)
    padding-top: 40px

.u-svgwrapper
  margin: auto

.u-imageshadow
  display: block
  box-shadow: 7px 9px 22px 1px rgba(0, 0, 0, .23)

  img
    margin-bottom: 0px

.u-desktop
  display: none
  +mq($from: tablet_ls)
    display: block
.u-mobile
  +mq($from: tablet_ls)
    display: none

.u-upper
  text-transform: uppercase

.u-no-padding
  padding-right: 0 !important

.u-margin-auto
  margin: auto

=u-trans($property: all)
  transition: $property .3s ease-in-out

.u-vivus
  display: block
.u-svg-ie
  display: none

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .u-vivus
    display: none
  .u-svg-ie
    display: block

.u-mb-auto
  margin-top: auto!important
.u-mt-auto
  margin-top: auto!important

/*
 * flex utilities
 */
.u-d-flex
  display: flex!important
.u-tablet-d-flex
  +mq($from: tablet)
    display: flex!important

.u-flex--jc-sb
  justify-content: space-between
.u-flex--ai-fe
  align-items: flex-end

.u-pos-r
  position: relative!important
.u-pos-a
  position: absolute!important
