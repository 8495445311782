.headline
  max-width: 1440px
  margin: auto
  text-align: left
  position: relative
  height: 500px
  @media screen and (max-height: 1000px)
    height: 300px

  +mq($until: desktop)
    margin-top: 6rem
    height: 200px
  +mq($until: tablet)
    margin-top: 6rem
    height: auto
  +mq($until: phablet)
    margin-top: 2rem
    height: 230px

  &__text
    animation: hl_appear_2 1s forwards
    animation-delay: 0.5s
    position: absolute
    top: 130px
    padding-top: 180px
    opacity: 0
    z-index: 99

    +mq($until: tablet)
      top: 0px
      padding-top: 0px
      position: relative
    +mq($until: desktop)
      padding-top: 30px

    @media screen and (max-height: 1000px)
      top: 80px
      h1
        font-size: 100px

    h1
      font-size: 168px
      line-height: 150px
      +f-bold
      color: c(grey, d)
      +mq($until: desktop)
        font-size: 100px
        line-height: 110px

      +mq($until: tablet)
        margin-top: 0px
        font-size: 50px

    &--yellow
      h1,h2,h3
        color: c(primary, 500)