.grenzen-ueberschreiten

  .story--header
    .content
      .col--7
        padding-top: 100px
        padding-bottom: 50px

        +mq($from: phablet)
          padding-top: 150px
          padding-bottom: 100px

  .stars
    &--blue
      bottom: 20px
      left: 0
      position: absolute

      +mq($from: tablet_ls)
        bottom: 60px

    &--yellow
      position: absolute
      left: 0
      top: 0
      z-index: -1
