// Display-Fonts
@font-face
  font-family: Gotham-Book
  src: url(~/assets/fonts/Gotham-Book.otf) format("opentype"), url(~/assets/fonts/Gotham-Book.otf) format("opentype")

@font-face
  font-family: Gotham-Bold
  font-weight: Bold
  src: url(~/assets/fonts/Gotham-Bold.otf) format("opentype"), url(~/assets/fonts/Gotham-Bold.otf) format("opentype")


// Text Fonts
@font-face
  font-family: Gotham-Book
  src: url(~/assets/fonts/Gotham-Book.otf) format("opentype"), url(~/assets/fonts/Gotham-Book.otf) format("opentype")

@font-face
  font-family: Gotham-Black
  font-weight: Black
  src: url(~/assets/fonts/Gotham-Black.otf) format("opentype")

@font-face
  font-family: Gotham-Medium
  font-weight: Medium
  src: url(~/assets/fonts/Gotham-Medium.otf) format("opentype")


%f_regular
  font-family: Gotham-Book, Arial, Verdana, sans-serif
@mixin f_regular
  @extend %f_regular



%f_bold
  font-family: Gotham-Bold, Arial, Verdana, sans-serif
@mixin f_bold
  @extend %f_bold



%f_medium
  font-family: Gotham-Medium, Arial, Verdana, sans-serif
@mixin f_medium
  @extend %f_medium



%f_superbold
  font-family: Gotham-Bold, Arial, Verdana, sans-serif
  font-weight: bold
@mixin f_superbold
  @extend %f_superbold
