.figure
  &--caption-overlay
    figcaption
      +u-font(16, 24)
      margin-top: 0
      margin-bottom: 1rem
      padding: 2rem

    .figure__title
      +text-caps
    .figure__subtitle
      +f_regular

    &.figure--from-left
      img
        padding-left: $spacing-small
        +mq($from: tablet)
          padding-left: 20%
      figcaption
        margin-right: $spacing-small
        transform: translateY(-20%)

        +mq($from: tablet)
          margin-right: 25%

    &.figure--from-right
      img
        padding-right: $spacing-small
        +mq($from: tablet)
          padding-right: 20%
      figcaption
        margin-left: $spacing-small
        transform: translateY(-20%)

        +mq($from: tablet)
          margin-left: 25%
