.multitalent-wasserstoff
  .dot
    position: absolute

    img
      height: 30px
      width: auto

    @for $i from 1 through 22
      $step: $i * 5
      &[data-x="#{$step}"]
        @if($step < 50)
          left: $step * 2.5 * 1%
        @else
          left: $step * 1%
        +mq($from: tablet)
          left: $step * 1%

      &[data-y="#{$step}"]
        top: $step * 1%

  .story--header
    align-items: center
    display: flex
    flex-direction: column
    height: 580px
    justify-content: center

    &__title
      color: c(purple, base)
      display: block
      +f_bold
      +u-font(32, 32)
      text-transform: uppercase
      hyphens: auto

      +mq($from: phablet)
        +u-font(48, 48)
      +mq($from: tablet)
        +u-font(100, 100)
