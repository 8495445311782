.financial
  &--tile
    width: 100%
    height: 340px
    background: #9e007e
    -webkit-border-radius: 0px 0px 50px 0px
    border-radius: 0px 0px 50px 0px
    &:hover
      box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
      -webkit-box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
      -moz-box-shadow: 0px 2px 9px 3px rgba(0,0,0,0.27)
      .financial--text
        color: #FFF
        opacity: 0.85
    &__further
      h2
        +u-font(25, 25)
        text-align: left
        padding-left: 30px
      &:hover
        background: #9e007e
        h2
          +u-font(25, 25)
          color: #fff

  &--text
    padding-top: 30px
    padding-left: 30px
    font-size: 25px
    font-weight: bold
    color: #fff
    +f_bold



