.wind-und-wellen-trotzen
  .container__schutz-der-natur

    +mq($from: mobile, $until: phablet)
      margin-bottom: 100px

    +mq($from: phablet, $until: tablet_ls)
      margin-bottom: 160px

    .content
      +mq($until: tablet)
        padding-left: 50px!important
        padding-right: 50px!important

    .figure
      transform: translateY(60%)

      +mq($from: phablet, $until: tablet)
        transform: translateY(50%)

      +mq($from: tablet, $until: tablet_ls)
        transform: translateY(30%)

    .lottie__container
      +mq($until: phablet)
        max-width: 80%
      +mq($from: phablet, $until: tablet_ls)
        max-width: 80%

  .lottie__container.messlatte
    svg
      max-height: 300px

      +mq($from: phablet)
        max-height: 380px
