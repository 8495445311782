.footer
  background: c(grey, d)
  text-align: center

  +mq($until: phablet)
    height: 640px

  ul
    list-style-type: none

    li
      display: inline-block
      margin-right: 3rem
      height: 35px
      overflow: hidden

      &:nth-child(6)
        width: auto

      &:last-child
        width: auto
        margin-right: 0
      +mq($until: tablet)
        margin-bottom: 8px
  ul.first
    li
      +mq($until: tablet_ls)
        display: block
        margin: auto
        margin-bottom: 20px
  ul.social
    li
      margin-right: 1rem
  a
    position: relative

    &:hover
      .footer__social__wrapper
        padding-top: -40px !important

  &__social__wrapper
    height: 70px

  &__social__item
    width: 50px
    height: 50px


  &__top
    height: 90px
    background: c(grey, d)
    +mq($until: phablet)
      height: auto

  &__item

    &--social
      margin-bottom: .5rem
      width: 100%
      height: 50px
      margin-left: 20px
      text-align: center
      // padding-top: 30px
      ul li
        height: max-content



      +mq($until: tablet)
        padding-top: 20px
        text-align: center
        width: 100%
        line-height: 15px

      a
        color: c(white)
        +f-bold
        // text-transform: uppercase
        display: inline-block

        svg
          float: left
          margin-right: 10px

          *
            fill: c(white)

    &--nav
      margin-bottom: 50px
      ul
        margin-bottom: 10px
        li
          a
            font-size: 24px
            +f_bold

      ul.first
        margin-bottom: 30px

        li
          a
            font-size: 24px
            +f_bold

  &__bottom
    +content-mq()
    padding-top: 50px


  a, p
    +f_bold
    color: c(grey, md)
    +u-font(20, 24)
    margin-bottom: 0
    transition: all .2s

  p
    +mq($until: tablet)
    display: block
    padding-top: 8px
    width: 100%
    +u-font(14, 18)

  &-copyright
    +mq($until: phablet)
      margin-top: 20px
      font-size: 14px !important
      line-height: 25px

.a--right
  float: right

p.contact
  font-size: 24px