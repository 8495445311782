.lottie-fact
  +mq($from: tablet)
    max-width: 200px

  &--big
    max-width: 360px

  &__number
    +u-font(120, 120)
    +f_bold

    //+mq($until: tablet)
      text-align: right

  &__text
    +u-font(18, 24)
    +f_bold
