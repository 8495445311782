

.composition
  .text
    p
      +u-font(13, 16)
  .highlight
    span.high
      +f_bold
      +u-font(30, 50)
      color: #fff
      padding: 8px
      margin-bottom: 7px
      &--green
        background: c(green, l)


  &--1
    .text
      p
        width: 250px
        +u-font(13, 16)
    .highlight
      width: 700px
      span.high
        +f_bold
        +u-font(30, 50)
        color: #fff
        padding: 8px
        margin-bottom: 7px
        padding-right: 10px
        &--green
          background: c(green, l)
    +mq($until: tablet)
      .image--1
        margin-top: 0px !important
      .u-marleft--18
        position: relative
        top: -60px
      .image--2
        z-index: -1
    +mq($until: phablet)
      *
        position: relative
        left: 0px
        width: 100%
        margin-left: 0px !important
      .highlight
        *
          line-height: 38px





  &--image

    img
      position: absolute
      width: 1000px
      height: 660px
      z-index: 2
      border-radius: 0px 0px 65px 0px
  &--background
    position: absolute
    width: 1100px
    height: 800px
    background: c(primary, base)
    z-index: 1
    border-radius: 0px 0px 65px 0px



  &--text
    width: 500px
    z-index: 3
    position: absolute
    p
      color: #FFF
      +u-font(32, 38)
      +f_bold
      z-index: 3
  &--sub-text
    p
      color: #fff
      z-index: 3
      +u-font(13, 16)
      +f_medium

.composition--2
  .image
    &--1
      img
        position: absolute
        left: 600px
        width: 631px
        height: 457px
    &--2
      img
        position: absolute
        width: 236px
        height: 354px
        left: 410px
        top: 240px
  .text
    p
      +u-font(13, 16)
      width: 260px
      top: 110px
      left: 280px
      position: absolute
  .highlight
    width: 500px
    background: #FFF
    left: 280px
    p
      width: 500px
      +u-font(30, 34)
      background: c(green, l)
      color: #fff
      box-shadow: 0.5em 0 0 c(green, l),-0.5em 0 0 c(green, l)
      span
        padding-top: 10px

.composition--3

  +mq($until: phablet)
    .text
      top: 10px !important
    *
      position: relative
      left: 0px
      width: 100%
      margin-left: 0px !important
      margin-top: 0px !important

  .image
    &--1
      width: 380px
      height: 567px
      +mq($until: phablet)
        width: 100%

  +mq($until: tablet)
    .text
      p
        width: 140px !important
  +mq($until: phablet)
    .text
      p
        width: 100% !important
        top: unset !important

    &--2

      left: 600px
      top: 400px
      width: 425px
      height: 304px
  .text
    p
      +u-font(13, 16)
      width: 250px
      top: 620px

  .highlight
    span.high
      +f_bold
      +u-font(30, 50)
      color: #fff
      padding: 8px
      margin-bottom: 7px
      &--green
        background: c(green, l)
    +mq($until: tablet)
      position: absolute
      bottom: -210px
      left: 0px
    +mq($until: phablet)
      position: relative
      left: 0px
      bottom: 17px
      margin-left: 0px !important
      *
        line-height: 38px !important



.composition--4
  .highlight
    p
      +u-font(30, 34)
      position: absolute
      width: 400px
      left: 700px
      top: 40px
      background: c(green, l)
      color: #fff
    &--sub
      position: absolute
      left: 700px
      top: 170px


.composition--5
  margin: auto
  .image
    &--1
      img
        position: absolute
        left: 600px
        width: 631px
        height: 457px
    &--2
      img
        position: absolute
        width: 425px
        height: 304px
        left: 210px
        top: 350px
  .text
    p
      +u-font(13, 16)
      position: absolute
      width: 425px
      left: 700px
      top: 500px


.composition--6
  position: relative
  .text
    p
      +u-font(13, 16)

  +mq($until: tablet)
    margin-bottom: 100px
    .image--1
      transform: translateX(50px)
    .text
      width: 50%
      float: right
      margin-top: 30px
    .image--2
      z-index: 999
      max-width: 270px
      position: absolute
      top: -300px
      left: -70px

  +mq($until: phablet)
    *
      position: relative !important
      right: unset !important
      top: unset !important
      left: unset !important
      bottom: unset !important
      transform: none !important
    .text
      width: 100% !important
      float: none !important
      margin-left: 0px !important







.composition--7
  .highlight
    z-index: 5
    width: 500px
    background: transparent
    left: 280px
    +mq($until: phablet)
      width: 100%
      line-height: 38px
      margin-bottom: 0px !important

    span.high
      +f_bold
      +u-font(30, 50)
      background: c(orange, base)
      color: #fff
      padding: 8px
      margin-bottom: 7px
      &--green
        background: c(green, l)
      &--orange
        background: c(orange, base)
    .sub--wrapper
      margin-left: 8px
      span.sub
        background: none
        +u-font(13, 26)
        color: #000
        text-transform: uppercase
      span.sub-line
        +u-font(13, 16)
        color: #000
        background: none
        width: 300px
  .text
    p
      +u-font(13, 16)

  +mq($until: tablet)
    .image--1,
    .u-marleft-neg--58
      margin-left: 0px !important
    .highlight
      left: 55px

  +mq($until: phablet)
    .col
      margin-left: 0px !important
    .highlight
      margin-left: 0px !important
    *
      position: relative !important
      right: unset !important
      top: unset !important
      left: unset !important
      bottom: unset !important
      transform: none !important
    .text
      width: 100% !important
      float: none !important
      margin-left: 0px !important




.composition--10
  +mq($until: tablet)
    position: relative

    .text
      p
        +u-font(13, 16)

    .image--2
      margin-left: 0px !important
      .text
        width: 50%
        float: right
    .image--1
      margin-left: 0px !important
      max-width: 300px
      margin-top: 0px !important
      position: absolute
      left: -70px !important
      bottom: 0px

  +mq($until: phablet)
    *
      position: relative !important
      right: unset
      top: unset
      left: unset
      bottom: unset
    .text
      width: 100% !important
      float: none !important
      margin-left: 0px !important



.composition
  position: relative
  .highlight
    position: absolute



    .high
      +mq($until: phablet)
        font-size: 16px !important
        line-height: 43px


    &.highlight--bottom
      bottom: 0
    span.high
      box-decoration-break: clone

      &--white
        background: c(white)
        color: black
    .sub--wrapper
      &.wrapper-white
        span
          color: white

.high
  +mq($until: phablet)
    font-size: 16px !important
    line-height: 36px

.composition--transformation
  +mq($until: tablet)
    position: relative
    height: auto
    margin-bottom: 50px
    .composition--image
      img
        max-width: 570px
        height: auto
    .composition--text
      left: -50px
    .composition--background
      height: 590px

  +mq($until: phablet)
    height: auto
    .composition--image
      img
        max-width: 300px
        height: auto
        left: 30px
    .composition--text
      margin-left: 0px !important
      left: 20px !important
      width: auto
      top: -80px
    .composition--background
      height: 590px


.national--1
  +mq($until: tablet)
    .sub
      margin-top: 30px


.highlight--sub
  +f_medium