.fact
  h2.title
    font-size: 24px
    margin-bottom: 0

  hr.divider
    padding-top: 0
    margin-top: 16px
    margin-bottom: 16px

  p.sub
    +f_bold
    text-transform: uppercase
    margin-bottom: 0px

  p.fact__bold
    +f_bold

  &--white
    background: c(white, 500)

  .orange
    color: c(primary, base)
    text-transform: uppercase


.facts
  &--wrapper
    position: relative

  &--title
    color: c(primary, base)
    font-weight: bold
    font-size: 30px

    +mq($until: phablet)
      font-size: 20px
      line-height: 28px



  &--desription
    color: c(primary, base)
    margin-top: 25px
    font-size: 20px


.accordion
  background-color: c(primary, base)
  cursor: pointer
  font-size: 20px
  width: 30px
  height: 30px
  font-weight: bold
  transition: 0.4s
  margin: auto auto auto 50%
  display: flex
  justify-content: center
  align-items: center

  &.active::before
    color: c(primary, base)
    transform: rotate(45deg)

  &::before
    padding-top: 4px
    content: '+'
    color: white
    width: max-content
    font-weight: 600
    display: block
    font-size: 32px
    transition: 0.3s all ease-in-out

  &.active
    background-color: white

.panel
  padding: 0 18px
  max-height: 0
  overflow: hidden
  transition: max-height 0.2s ease-out


