.excel-downloads-wrapper
  &--outer
    width: 940px
    margin: 0 auto 0 auto

    .excel-download-combined
      display: block
      box-sizing: border-box
      width: 100%
      height: 83px
      padding: 20px
      position: relative
      background: #D8D8D8
      margin-bottom: 15px
      &:hover
        .excel-download-combined--button
          transition: background 0.5s ease
          background-color: #F9B94F


      &--button
        position: absolute
        display: inline-block
        right: 0%
        width: 83px
        height: 83px
        padding: 20px
        background: #F5A623
        top: 0

      &--text
        position: absolute
        display: inline-block
        top: 50%
        transform: translateY(-50%)
        p
          color: #666666
          +f_bold
          margin: 0


  &--inner
    width: 910px
    margin: 0 auto 0 auto
    padding: 0 30px 0 30px

    .excel-download
      display: block
      box-sizing: border-box
      width: 100%
      height: 38px
      padding: 7px
      position: relative
      margin-bottom: 15px


      &:hover
        .excel-download--button
          transition: background 0.5s easeInOut
          background: #B2B2B2
        .excel-download--text
          p
            transition: color 0.5s ease
            color: #B2B2B2


      &--button
        position: absolute
        display: inline-block
        right: 0%
        width: 38px
        height: 38px
        padding: 7px
        background: #666666
        top: 0
        &:hover
          transition: background 0.5s ease
          background: #B2B2B2

      &--text
        position: absolute
        display: inline-block
        transform: translateY(-50%)
        top: 50%
        p
          color: #666666
          margin: 0
          +f_bold

.excel-download-combined--teaser
  display: inline-block
  max-width: unset
  &--img
    display: inline-block
    height: auto
    -ms-interpolation-mode: bicubic
    max-width: 100%
    width: auto
    max-height: 335px


