@mixin text-caps
  text-transform: uppercase
  letter-spacing: 0.2em

// h1, .h1, %h1,
// h2, .h2, %h2,
// h3, .h3, %h3,
// h4, .h4, %h4
//   padding-bottom: .5em
.text
  a
    text-decoration: none
    color: c(primary, base)
p.sub
  +u-font(13, 16)
  +f_medium
  &.spacing
    letter-spacing: 0.25em
  &.right
    text-align: right

span.sub
  +u-font(13, 16)
  +f_medium
  &.spacing
    letter-spacing: 0.25em

h1, .h1, %h1
  +f_bold
  +u-font(120, 130)
  margin-bottom: 30px
  +f_superbold

  +mq($until: desktop)
    font-size: 48px !important
  +mq($until: phablet)
    font-size: 28px !important

  &.story
    +u-font(70, 80)

  &.primary
    color: c(primary, base)

  &.cyan
    color: c(cyan, base)

  &.line
    position: relative
    &:after
      content: ''
      width: 40px
      height: 8px
      background: c(white)
      position: absolute
      bottom: -20px
      left: 0px

  &.title
    +u-font(72, 96)

    +mq($until: phablet)
      +u-font(40, 96)
      line-height: 1.2em
    +mq($until: tablet)
      margin-top: 50px !important

  &.small
    +u-font(32, 40)

  +mq($until: desktop)
    +u-font(40, 44)

  +mq($until: phablet)
    +u-font(35, 38)

h2, .h2, %h2
  +f_superbold
  +u-font(58, 64)
  // text-transform: uppercase
  color: c(black,500)
  margin-bottom: 30px

  +mq($until: desktop)
    +u-font(30, 38)

  &.small
    +u-font(24, 28)
    padding-bottom: 25px

  &.medium
    +u-font(40, 54)

  &.big
    +u-font(28, 40)
    padding-bottom: 25px

  &.bigger
    +u-font(74, 74)
    text-transform: inherit
    color: c(grey, d)

h3, .h3, %h3
  +f_superbold
  +u-font(16, 24)
  letter-spacing: 0.5px
  text-transform: uppercase
  margin-bottom: 10px

  +mq($until: desktop)
    +u-font(20, 28)

  +mq($until: phablet)
    +u-font(18, 25)
    margin-top: 5px

  &.no-margin
    margin-bottom: 5px

h3.fz-30
  +f_superbold
  +u-font(30, 36)

h4, .h4, %h4
  +u-font(25, 22)
  +f_bold
  text-transform: none

a
  text-decoration: none
  color: c(black)

p
  color: c(black)
  margin-bottom: 25px
  +u-font(16, 23)

  +mq($until: desktop)
    margin-bottom: 20px
  &.normal
    +f_regular

  &.stronk
    +f_bold

  &.intro
    +f-bold
    +u-font(24, 34)
    text-align: center
    color: c(black, 500)
    margin-bottom: 20px
    letter-spacing: 0.015em

    +mq($until: phablet)
      +u-font(16, 22)

  &.big
    +u-font(24, 36)

  &.large
    +u-font(20, 24)

  &.cite
    +u-font(30, 36)
    +f-bold

  &.medium
    +u-font(20, 28)

  &.smaller
    +u-font(13, 18)
    +f_medium

  &.no-margin
    margin-bottom: 0


span
  &.strong
    +f-bold
    display: block
    padding-bottom: 25px
  &.stronk
    +f_bold
  &.normal
    +f_regular

strong
  +f_bold

blockquote
  +f-bold
  +u-font(48,55)
  color: c(primary)
  cite
    font-size: 18px


small, .small
  +u-font(18, 18)

.title__meta
  text-transform: uppercase
  +f_bold
  font-size: 18px
  line-height: 25px
  margin-bottom: 10px
  display: block

.medium
  +u-font(17,20)
  +mq($until: desktop)
    +u-font(18, 17)
  +mq($until: tablet)
    line-height: 1.33333em

.large
  +u-font(45,60)
  +mq($until: desktop)
    +u-font(35, 40)
  +mq($until: tablet)
    line-height: 1.33333em

figure
  +mq($until: phablet)
    img
      padding-bottom: 20px

figcaption
  font-size: 0.95rem
  +f-bold
  letter-spacing: 0.030em
  color: c(black, 500)
  margin-top: 1rem

  +mq($until: desktop)
    +u-font(18, 17)

  +mq($until: tablet)
    margin-bottom: 2rem

  small
    color: c(black)

    +mq($until: desktop)
      +u-font(18, 17)

ul.dashed
  list-style-type: none
  margin-left: 1.5rem
  margin-bottom: 1rem
  padding-left: 1em
  li
    margin-bottom: 1em
    &:last-child
      margin-bottom: 0
    &::before
      display: inline-block
      content: "–"
      width: 1em
      margin-left: -1em

hr
  display: block
  height: 1px
  width: 100%
  border: none
  background-color: c(black)
  margin:
    top: 130px
    bottom: 0rem
    left: auto
    right: auto
  +mq($until: phablet)
    margin-top: 40px

  &.middle
    margin-top: 70px
    margin-bottom: 70px

  .section--primary &
    background-color: c(white)

.t-hero
  +u-font(70, 75)
  margin-bottom: 1em
  +f_bold

  +mq($until: phablet)
    +u-font(45, 50)

.t-ultra
  +u-font(200, 210)

  +mq($until: wide)
    +u-font(180, 190)

  +mq($until: desktop)
    +u-font(120, 150)

.t-center
  text-align: center

.t-right
  text-align: right
.t-tablet-right
  +mq($from: tablet)
    text-align: right

.t-left
  text-align: left

.t-xl
  +u-font(60, 60)

  +mq($from: tablet_ls)
    +u-font(120, 120)


.t-bottom
  vertical-align: bottom !important

.no-addendum
  display: none


p.t-medium, .t-medium
  +f_medium

.t-bold
  +f_bold
  font-weight: 600

.t-spacing
  letter-spacing: 0.25em

.upper
  text-transform: uppercase

.lower
  text-transform: none!important

.ls--normal
  letter-spacing: normal!important

.number
  +u-font(160,120)
  +f_bold
  color: c(primary,500)

  &.small
    +u-font(88,100)
    +mq($until: phablet)
      +u-font(40,100)


  &:before
    content: ''
    width: 2px
    height: 75px
    background: c(black, 500)
    display: block
    margin: auto
    margin-bottom: 50px

  &__caption
    padding-top: 22px
    text-transform: uppercase
    +u-font(24,32)
    +f_regular
    color: c(grey, d)

.vertical
  position: relative

  &__content
    position: absolute

    p
      margin-bottom: -10px

      +mq($until: desktop)
        margin-bottom: 0px


    +mq($until: wide)
      position: relative

    &--left
      transform: rotate(270deg)
      left: -280px
      padding-top: 20%

      +mq($until: desktop)
        margin-bottom: 30px

    &--right
      right: -150px
      transform: rotate(90deg)

    &--right,
    &--left
      +mq($until: wide)
        transform: none
        left: 0px
        padding: 20px 0px

.text--columns
  column-count: 2
  column-gap: 70px

  +mq($until: tablet)
    column-count: 1


.white
  color: #FFF

.black
  color: #000 !important
  p
    color: #000 !important

.purple
  color: c(purple, base)!important

.red
  color: c(red, base)!important

.turquoise
  color: c(turquoise, base) !important

.darkblue
  color: c(darkblue, base) !important
  background-color: c(white)

.circle--1
  position: relative
  top: 110px
  left: 21px

.signatur
  +f_medium
  text-transform: uppercase
  +u-font(14, 16)
  margin-bottom: 5px
.signatur-sub
  +u-font(13, 16)
