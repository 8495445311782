.was-kostet-die-zukunft
  .network-expansion
    display: flex
    //flex: 1 0 25%
    align-items: center

    &__img
      margin-bottom: 1rem
      &--w-25
        width: 25%
      &--w-50
        width: 50%
    .network-expansion__img + .network-expansion__img
      margin-left: 1rem

    &__text
      display: flex

      p + p
        margin-left: 2rem

      span
        display: block
        font-size: 13px
        +f_regular
