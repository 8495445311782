.image
  &__sub
    width: 100%
    padding-top: 25px
    text-align: left
    span
      font-size: 16px

  &--border-cut
    img
      border-radius: 0px 0px 65px 0px



  &.pad--right-20
    .image__sub
      padding-right: 20%

.signed
  width: 200px

.textbox--1
  +mq($until: desktop)
    position: relative
    left: 170px !important
