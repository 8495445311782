.showcase
  position: relative
  overflow: hidden
  max-width: 1440px
  margin-left: auto
  margin-right: auto

  +mq(tablet_ls)
    background-color: rgba(c(grey, l), .25)

  &__title
    +u-font(36, 34)
    +f_bold
    position: absolute
    top: 17px
    left: 35px
    width: 20%
    z-index: 3
    transition: color .2s ease
    color: rgba(c(grey, l), 0)

    +mq(phablet)
      top: 30px
      +u-font(48, 46)
      text-transform: uppercase

    +mq(tablet_ls)
      top: 60px
      left: 8%
      +u-font(70, 70)
      transition: color .4s 1s ease

  &__slides
    width: 100%
    height: 450px
    transition: height .6s ease-in-out

    +mq(tablet_ls)
      height: 840px
      overflow: hidden


  &__slide
    position: absolute
    top: 0
    left: 0
    right: 0

    +mq(tablet_ls)
      bottom: 0

  &__content
    position: relative
    box-sizing: border-box
    opacity: 0
    z-index: 1

    +mq($until: tablet_ls)
      margin: -20px 60px 20px

    +mq(phablet)
      margin-left: 20%
      margin-right: 20%
      width: 60%

    +mq(tablet_ls)
      position: absolute
      left: 8%
      width: 50%
      margin: 0

    +mq(wide)
      width: 40%

  &__subtitle
    position: relative
    display: inline
    padding: 6px .25em
    box-decoration-break: clone
    color: #fff
    +u-font(20, 26)
    +f_bold
    text-transform: none

    +mq(tablet_ls)
      +u-font(40, 52)
      width: 150%

  &__text
    +u-font(16, 24)
    +f_bold
    margin-top: 15px

    +mq(tablet_ls)
      margin-top: 30px
      padding-right: 50%

  &__media
    position: relative
    text-align: center
    overflow: hidden
    height: 220px
    opacity: 0
    transition: height .6s ease-in-out

    +mq(phablet)
      height: 360px

    +mq(tablet)
      height: 480px

    +mq(tablet_ls)
      left: 100%
      margin-left: 40%
      height: 840px

    +mq(wide)
      margin-left: 30%

  &__img
    width: 100%
    height: 220px
    background: no-repeat center / cover
    transition: height .6s ease-in-out

    +mq(phablet)
      height: 360px

    +mq(tablet)
      height: 480px

    +mq(tablet_ls)
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      height: auto
      transform: scale(1.35)
      transition: transform .8s 1s ease-in-out

  &__nav
    position: absolute
    top: 50%
    width: 100%
    margin-top: -37px
    height: 73px

  &__prev, &__next
    position: absolute
    top: 0
    height: 73px
    width: 45px
    text-indent: -999em
    cursor: pointer
    background: rgba(255, 255, 255, .85) no-repeat center / 18px 34px

    +mq(tablet_ls)
      background-size: 20px 40px

  &__prev
    left: 0
    border-radius: 0 4px 4px 0
    background-image: url(~/assets/images/02_Bildintro/pfeil-slider-links.svg)

  &__next
    right: 0
    border-radius: 4px 0 0 4px
    background-image: url(~/assets/images/02_Bildintro/pfeil-slider-rechts.svg)

  &__dots
    text-align: center
    margin-bottom: 20px

    +mq(tablet_ls)
      display: none

  &__dot
    border-radius: 50%
    display: inline-block
    width: 19px
    height: 19px
    margin: 0 5px
    border: 1px solid c(cyan, base)
    background-color: #fff
    cursor: pointer

    &--active
      background-color: c(cyan, base)


  // Modifier

  &--life
    .showcase__title
      color: c(turquoise, base)

  &--economy
    .showcase__title
      color: c(cyan, base)

  &--renewables
    .showcase__title
      color: c(orange, base)

  &--sustainability
    .showcase__title
      color: c(green, gift)

  &__slide--active
    .showcase__content
      +mq($until: tablet_ls)
        transition: opacity .6s ease-in-out
        opacity: 1
      +mq(tablet_ls)
        animation: .6s 1s ease-in-out forwards showcaseContentEntrance

    .showcase__media
      +mq($until: tablet_ls)
        transition: opacity .6s ease-in-out
        opacity: 1
      +mq(tablet_ls)
        animation: .6s ease-out forwards showcaseMediaEntrance

    .showcase__img
      +mq(tablet_ls)
        transform: scale(1)

  &__slide--fadeout
    .showcase__content
      +mq($until: tablet_ls)
        transition: opacity .6s ease-in-out
        opacity: 0
      +mq(tablet_ls)
        animation: .6s ease-in-out forwards showcaseContentExit
        bottom: 35px

    .showcase__media
      +mq($until: tablet_ls)
        transition: opacity .6s ease-in-out
        opacity: 0
      +mq(tablet_ls)
        animation: .6s ease-out forwards showcaseMediaExit

  &__slide--life
    .showcase__text
      color: c(turquoise, base)

    .showcase__subtitle
      background-color: c(turquoise, base)

    .showcase__img
      background-image: url(~/assets/images/02_Bildintro/amprion-02-bildintro-festival-1150x840.jpg)

  &__slide--economy
    .showcase__text
      color: c(cyan, base)

    .showcase__subtitle
      background-color: c(cyan, base)

    .showcase__img
      background-image: url(~/assets/images/02_Bildintro/amprion-02-bildintro-schweisser-1150x840.jpg)

  &__slide--renewables
    .showcase__text
      color: c(orange, base)

    .showcase__subtitle
      background-color: c(orange, base)

    .showcase__img
      background-image: url(~/assets/images/02_Bildintro/amprion-02-bildintro-paar-1150x840.jpg)

  &__slide--sustainability
    .showcase__text
      color: c(green, gift)

    .showcase__subtitle
      background-color: c(green, gift)

    .showcase__img
      background-image: url(~/assets/images/02_Bildintro/amprion-02-bildintro-acker-1150x840.jpg)


// Animations

@keyframes showcaseMediaEntrance
  from
    left: 100%
    opacity: 0
  to
    left: 0
    opacity: 1

@keyframes showcaseMediaExit
  from
    left: 0
    opacity: 1
  to
    left: -100%
    opacity: 0

@keyframes showcaseContentEntrance
  from
    bottom: -350px
    opacity: 0
  to
    bottom: 35px
    opacity: 1

@keyframes showcaseContentExit
  from
    left: 8%
    opacity: 1
  to
    left: -50%
    opacity: 0
