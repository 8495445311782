.chapter-headline
  max-width: 1440px
  margin: auto
  text-align: left
  position: relative
  height: 740px



  // +mq($until: desktop)
  //   margin-top: 6rem
  // +mq($until: tablet)
  //   margin-top: 6rem
  +mq($until: desktop)
    height: auto
  +mq($until: phablet)
    margin-top: 2rem

  &__image
    background-color: c(primary)
    position: absolute
    right: 0px
    height: 100%
    width: 68%
    background-size: cover

    +mq($until: desktop)
      width: 100%
      height: 700px
      position: relative

    +mq($until: tablet)
      height: 500px
      background-position: center


  &__headline
    animation: hl_appear_2 1s forwards
    animation-delay: 0.5s
    position: absolute
    top: 130px
    padding-left: 180px
    padding-top: 180px
    opacity: 0
    z-index: 99
    width: 57%
    +mq($until: phablet)
      width: 100%
      padding-left: 30px
      padding-top: 0px


    h1
      font-size: 168px
      line-height: 150px
      +f-bold
      margin-bottom: 20px

      +mq($until: tablet)
        margin-top: 100px
        font-size: 50px !important
        line-height: 58px !important

    +mq($until: desktop)
      position: relative
      padding-top: 120px
      padding-bottom: 40px
      h1
        font-size: 120px
        line-height: 128px

    +mq($until: tablet)
      padding-top: 20px
      padding-left: 110px
      h1
        font-size: 100px
        line-height: 78px


  &--grey
    .chapter-headline__headline
      h1
        color: c(grey, d)
  &--yellow
    .chapter-headline__headline
      h1
        color: c(primary)



.no-addendum
  .chapter-headline__addendum
    p
      display: none


@keyframes hl_appear_1
  0%
    opacity: 0
  100%
    opacity: 1


@keyframes hl_appear_2
  0%
    opacity: 0
    top: 150px
  100%
    opacity: 1
    top: 0px

@keyframes hl_appear_3
  0%
    opacity: 0
    bottom: 150px
  100%
    bottom: 0px
    opacity: 1

.big
  -webkit-background-size: 100%
  -o-background-size: 100%
  background-size: 100%
  .chapter-headline__image
    width: 100%


.header
  &--transplosion
    position: relative
    height: 800px

    +mq($until: tablet)
      height: 600px

    +mq($until: phablet)
      height: auto


    &__left
      padding-left: 80px
      padding-top: 200px
      +mq($until: tablet)
        padding-left: 50px
        padding-top: 150px
      +mq($until: phablet)
        padding-top: 40px
        padding-bottom: 40px


      h1
        font-size: 168px
        line-height: 150px
        color: c(grey, d)
        display: block
      span
        padding-top: 30px
        font-size: 48px
        line-height: 55px
        color: c(primary, 300)
        +f_bold
        display: block
        max-width: 450px

      +mq($until: desktop)
        h1
          font-size: 100px
          line-height: 100px
        span
          font-size: 30px
          line-height: 34px
          max-width: 300px

      +mq($until: tablet)
        h1
          font-size: 70px
          line-height: 74px
        span
          font-size: 30px
          line-height: 34px
          max-width: 300px



    &__right,
    &__img
      position: absolute
      right: 110px
      top: 120px
      max-width: 415px

      +mq($until: tablet)
        max-width: 335px
        right: 40px
      +mq($until: phablet)
        position: relative
        top: 0px
        right: 0px
        max-width: 100%


    &__name
      position: absolute
      right: 570px
      bottom: 150px
      span
        font-size: 18px
        line-height: 20px

      +mq($until: tablet)
        bottom: 0px
      +mq($until: phablet)
        position: relative

    &__bg
      height: 380px
      position: absolute
      top: 200px
      width: 55%
      right: 0px
      background-color: c(grey, l)
      z-index: -1
      +mq($until: phablet)
        display: none