.flex
  &--a-center
    img &
      display: flex
      align-items: center
      justify-content: center
      align-self: center

.flex
  display: flex!important
  +mq($until: tablet)
    flex-direction: column

  &-content--end
    justify-content: flex-end
  &--row
    flex-direction: row
  &--v-center
    align-items: center
  &--self-center
    align-self: center
  &--self-end
    align-self: flex-end
