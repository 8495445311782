.story-credits

  &__label, &__text
    +u-font(13, 18)

  &__label
    +f_bold
    margin-right: .5rem

  &__text
    letter-spacing: 1px
    text-transform: uppercase
    +f_medium
