$palettes: (
  white: (
    500: #fff,
  ),
  black: (
    500: #000,
  ),
  grey: (
    l: #D9D9D6,
    m: #ededed,
    d: #B1B3B3,
    dd: #212529,
  ),
  primary: (
    base: #9e007e,
  ),
  secondary: (
    500: #dadada,
  ),
  cyan: (
    base: #00A9E0,
  ),
  green: (
    base: #749f4a,
    gift: #93C90E,
    l: #93c90e,
    m: #00894c
  ),
  turquoise: (
    base: #00B097
  ),
  purple: (
    base: #5C2483,
    berry: #9E007E
  ),
  red: (
    base: #E40046,
  ),
  yellow: (
    base: #FFD100,
  ),
  orange: (
    base: #FF9E1B,
  ),
  pink: (
    base: #E40046,
    ),
  darkblue: (
    base: #2268B1,
    )
);



@function c-tone-exists-check($palette, $tone) {
  @if map-has-key($palettes, $palette) == false {
    // @warn($palette, $tone);
    @return false;
  }

  @if map-has-key(map-get($palettes, $palette), $tone) == false {
    // @warn($palette, $tone);
    @return false;
  }

  @return true;
}

@mixin c($palette, $tone: 500) {
  @if c-tone-exists-check($palette, $tone) {
  }

  color: map-get(map-get($palettes, $palette), $tone);
}

@function c($palette, $tone: 500) {
  @if c-tone-exists-check($palette, $tone) {
  }

  @return map-get(map-get($palettes, $palette), $tone);
}

@mixin bg($palette, $tone: 500) {
  background-color: map-get(map-get($palettes, $palette), $tone);
}

/**
 * color variants
 * @example: .c_green-base
 * @example: .c_purple-berry__hover
 */
@each $palette_key, $palette in $palettes {
  @each $name, $color in $palette {
    .c_#{unquote("#{$palette_key}-#{$name}")} {
      color: $color;
    }

    .c_#{unquote("#{$palette_key}-#{$name}")}__hover:hover {
      color: $color !important;
    }

    .bg_#{unquote("#{$palette_key}-#{$name}")} {
      background-color: $color;
    }

    .bg_#{unquote("#{$palette_key}-#{$name}")}__hover:hover {
      background-color: $color;
    }
  }
}
