//Animations for Flags

@keyframes flyOutTop
  0%
    transform: translateY(0)
  100%
    transform: translateY(-300%)

@keyframes flyInBottom
  0%
    transform: translateY(300%)
  100%
    transform: translateY(0)
  //animation: flyOutTop 0.5s forwards

.text__item
  display: none
  padding: 20px
  height: max-content

  //&--1

  //&--2
  //animation: move2to3 0.5s forwards

  &.visible
    flex-direction: column
    display: flex


.slider

  &-further
    +mq($until: tablet)
      padding: 0px 40px !important

  img
    margin-bottom: 0px

  &-futher__img
    +mq($until: phablet)
      padding: 30px

  &__flag
    display: none

    +mq($from: tablet)
      display: block

    &--mobile
      display: block

      +mq($from: tablet)
        display: none

  &__section
    max-width: 1440px
    margin: 0 auto

  &__wrapper
    position: relative

  &__container
    //overflow: hidden
    height: 840px

    &--text
      pointer-events: none
      width: 100%
      height: 100%
      top: 0
      left: 0
      position: absolute
      display: flex
  //border: 1px solid green

  &__item
    display: flex !important

    &-img--70
      position: relative
      object-fit: cover
      overflow: hidden
      width: 70%
      box-sizing: border-box

    &-img--30
      position: relative
      overflow: hidden
      width: 30%
      object-fit: cover
      box-sizing: border-box

  .slick-prev,
  .slick-next
    background: none
    position: absolute
    bottom: 50%
    transform: translateY(-50%)
    font-size: 0px
    border: none
    z-index: 9999
    cursor: pointer
    transition: all 0.3s ease-in-out

    +mq($until: tablet)
      bottom: 0px
    +mq($until: phablet)
      bottom: inherit
      top: 90px

    &:after
      content: ''
      width: 20px
      height: 20px
      border-top: 3px solid c(primary, base)
      border-right: 3px solid c(primary, base)
      display: block

  .slick-list
    min-height: 720px
    overflow: hidden

    +mq($until: tablet)
      min-height: 450px
      height: auto

  .slick-dots
    text-align: center
    padding-top: 50px
    +mq($until: tablet)
      padding-top: 00px

    li
      display: inline-block
      padding: 5px

      button
        font-size: 0px
        border: 0px
        background: none

      &.slick-active
        &:before
          background: c(primary, base)

      &:before
        content: ''
        width: 10px
        height: 10px
        background: c(grey, d)
        border-radius: 30px
        display: block
        cursor: pointer
        transition: all 0.3s ease-in-out

  .slick-prev
    left: 0
    //width: 45px
    //height: 73px
    padding: 17px 10px
    background: c(white)

    &:after
      left: 13px
      transform: rotate(-135deg)

  //&:hover
  //  left: 45px
  //
  //+mq($until: phablet)
  //  left: 0px
  //  &:hover
  //    left: 0px

  .slick-next
    right: 0
    transform: rotate(45deg)

  //&:hover
  //  right: 45px
  //
  //+mq($until: phablet)
  //  right: 0px
  //  &:hover
  //    right: 0px

  //.slick-slide.slick-active + .slick-slide
  //transform: translateX(100px)

  .slick-slide
    position: relative

    img
      height: 100%


    &.slick-active

      .slider__item__txt
        opacity: 1

        p
          animation: slider_reveal 0.5s ease-in-out forwards
          animation-delay: 0.5s

        @keyframes slider_reveal
          0%
            opacity: 0
            transform: translateX(50px)
          100%
            opacity: 1
            transform: translateX(0px)

      .slider__item__title
        animation: title_reveal 0.5s ease-in-out forwards
        animation-delay: 0.5s

        @keyframes title_reveal
          0%
            opacity: 0
            left: -200px
          100%
            opacity: 1
            left: 10px

.slick-arrow
  outline: none

  &:hover
    cursor: pointer


